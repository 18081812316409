<template>
  <v-alert
    :key="refresh_num"
    :modelValue="show" @input="onToggle"
    tile density="compact"
    :type="alert ? alert.type : 'info'"
    transition="scroll-y-transition"
    :class="[alert ? alert.type : '']"
  >
    <div v-for="(msg, i) in alert ? alert.message : []" :key="i">{{ msg }}</div>
  </v-alert>
</template>

<script>
import AlertService from '@/services/alertservice'
import { alert } from '@/services/alertservice'

export default {
  data: () => ({
    show: false,
    closable: true,
    refresh_num: 0,
    timeout: null,
  }),
  computed: {
    alert() { return alert }
  },
  mounted() {
    AlertService.setListener(this.handler)
  },
  methods: {
    handler(newVal) {
      this.show = newVal.show
      if (this.alert.callback) {
        this.closable = true
      } else {
        this.closable = false
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.show = false
        }, this.alert.duration)
      }
      this.refresh()
    },
    onToggle() {
      this.show = !this.show
      if (!this.show && this.alert.callback) {
        this.alert.callback()
      }
    },
    refresh() {
      this.refresh_num++
    }
  }
}
</script>

<style lang="scss">
.v-application {
  .v-alert {
    position: fixed;
    color: white;
    bottom: 10px;
    right: 10px;
    z-index: 300;
//     left: 50%;
//     transform: translateX(-50%);
//     -webkit-transform: translateX(-50%);
//     margin-top: 20px;
//     &__border {
//       border-width: 8px;
//     }
//     &__icon {
//       display: none;
//     }
//     &__content {
//       margin-left: 12px;
//     }
//     &.info {
//       background-color: white!important;
//       // color: $accent-color-positive !important;
//       // border-color: $accent-color-positive !important;
//     }
//     &.success {
//       background-color: white!important;
//       color: #4CAF50 !important;
//       border-color: #4CAF50 !important;
//     }
//     &.warning {
//       background-color: white!important;
//       color: #FFC107 !important;
//       border-color: #FFC107 !important;
//     }
//     &.error {
//       // background-color: lighten($accent-color-negative, 53%)!important;
//       // color: $accent-color-negative !important;
//       // border-color: $accent-color-negative !important;
//       // .v-btn {
//       //   background: $accent-color-negative !important;
//       //   color: white;
//       // }
//     }
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3)!important;
  }
}
</style>
