import axios from 'axios'

export default class HttpClient {
  /**
   * リクエスト送信前のフック
   * onReqest: (request) => {...}
   * 
   * レスポンス受信後（成功時）のフック
   * onResponseSucceeded: (response) => {...}
   * 
   * レスポンス受信後（失敗時）のフック
   * onResponseFailed: (response) => {...}
   * 
   * 認証切れの場合の挙動を上書きする場合に指定、デフォルトは401の場合にトップページに遷移
   * onAuthenticationFailed: (response) => {...}
   */
  options = null

  constructor(options) {
    this.options = options || {};
    this.axios = axios.create({
      baseURL: location.origin,
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json'
    });

    this.axios.interceptors.request.use(request => {
      if (this.options?.onRequest) {
        this.options.onRequest(request);
        return request
      }
      return request
    })

    this.axios.interceptors.response.use(response => {
      if (this.options?.onResponse) {
        this.options.onResponse(request);
        return response
      }

      return response
    }, error => {
      if (this.options?.onResponseFailed) {
        this.options.onResponseFailed(response);
        throw(error);
      }

      // 認証切れ
      if (error.response && error.response.status === 401) {
        location.href = '/';
      }

      throw(error);
    })
  }

  async get(uri, params) {
    return this.axios.get(uri, params);
  }

  async post(uri, params) {
    return this.axios.post(uri, params);
  }

  async postFile(uri, params) {
    return this.axios.post(uri, params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  async put(uri, params) {
    return this.axios.put(uri, params);
  }

  async delete(uri) {
    return this.axios.delete(uri);
  }
}