<template>
  <section class="maps-view">
    <div>
      <Console
        modalType="wide"
        :service="restService"
        :itemName="itemName"
        :fields="fields"
        :searchQuery="text"
        :customQuery="customQuery"
        :tableActions="tableActions"
        :limit="limit"
        :rowClickAction="rowClickAction"
      >
        <template #control><span></span></template>
        <!-- <template #search="{submit}">
          <v-row align="center" justify="start">
            <v-col cols="auto">
              <ConsoleSearch
                v-model="text"
                @submit="submit"
                :searchByButton="false"
              />
            </v-col>
          </v-row>
        </template> -->
      </Console>

      <v-snackbar v-model="showNotify" text :color="notifyType">
        <v-icon v-if="notifyType == 'success'" color="success" class="mr-4">check_circle</v-icon>
        <v-icon v-else-if="notifyType == 'warning'" color="warning" class="mr-4">warning</v-icon>
        <v-icon v-else-if="notifyType == 'error'" color="error" class="mr-4">error</v-icon>
        <span v-html="notifyMessage"></span>
      </v-snackbar>
    </div>
  </section>
</template>

<script>
import Restservice from '@/services/restservice'

import Console from '@/components/console/Console'
import ConsoleModal from '@/components/console/ConsoleModal'
import ConsoleSearch from '@/components/console/ConsoleSearch'
import LoadingOverlay from '@/components/LoadingOverlay'
import {_has} from '@/utils/common'

export default {
  components: {
    Console,
    ConsoleSearch,
    ConsoleModal,
    LoadingOverlay,
  },
  data() {
    return {
      isImportDialogOpen: false,
      isExportDialogOpen: false,
      showNotify: false,
      notifyType: "succsess",
      notifyMessage: "",

      text: '',
      maintenance: false,

      restService: new Restservice('/api/maps/'),
      itemName: 'マップ',
      fields: [
        { id: 1, label: '#', key: 'id', type: 'string', sortable: true, width: '50px'},
        { id: 2, label: 'タイトル', key: 'name', type: 'string', sortable: true},
      ],
      tableActions: storelocator.analytics_only ? [] : [
        {label: '店舗管理', action: 'custom', func: (id) => {window.location.href = `/console/maps/${id}/points/`}},
        {label: 'デザイン設定', action: 'custom', func: (id) => {window.location.href = `/console/maps/${id}/design/`}},
      ],
      limit: 50,
      rowClickAction: {action: "custom", func: (id) => {
        window.location.href = `/console/maps/${id}/analytics/`;
      }},
    }
  },
  mounted() {
  },
  computed: {
    customQuery() {
      return {
        text: this.text
      }
    },
  },
  methods: {
    showNotification(message, status='success') {
      this.notifyType = status
      this.notifyMessage = message
      this.showNotify = true
    },
    onCloseModal() {
      this.importLoading = false;
      this.clearImportData();
    },
  }
}
</script>

<style lang="scss">
.maps-view {
  .console-search {
    display: none;
  }  
}
</style>