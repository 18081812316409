<template>
  <v-overlay class="overlay" :modelValue="isActive" absolute color="#fff" opacity=".8">
    <v-progress-circular
      indeterminate
      size="64"
      color="#666"
    ></v-progress-circular>
    <p>{{message}}</p>
  </v-overlay>
</template>
<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ""
    },
  }
}
</script>
<style lang="scss" scoped>
  .overlay {
    text-align: center;
    p {
      color: #666;
    }
  }
</style>