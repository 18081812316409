import "core-js/stable"
import "regenerator-runtime/runtime"
import { createApp } from 'vue'
import { VApp, VAppBar, VMain, VToolbarTitle } from 'vuetify/components'

import { initializeApp } from 'firebase/app';
import {
  getAuth,
  connectAuthEmulator,
} from "firebase/auth";

import AccountLoginView from './views/account/login'
import AccountActionsView from './views/account/actions'
import AccountManageView from './views/account/manage'
import AccountPasswordChangeView from './views/account/password'
import AccountMfaSettingView from './views/account/multiFactorAuth'

import PointsView from './views/console/points'
import MapsView from './views/console/maps'
import DesignView from './views/console/design'
import AnalyticsView from './views/console/analytics'
import EventsView from './views/console/analytics/events'

import vuetify from './plugins/vuetify'
const templateComponents = { VApp, VAppBar, VMain, VToolbarTitle }

/**
 * Firebase初期設定
 */
let firebaseConfig = {};
if(process.env.FIREBASE_PROJECT_ID) {
  firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: `${process.env.FIREBASE_PROJECT_ID}.firebaseapp.com`,
  };
}
initializeApp(firebaseConfig);
const auth = getAuth();
auth.useDeviceLanguage()
if(process.env.APP_MODE == 'development' && process.env.FIREBASE_AUTH_EMULATOR_HOST) {
  const firebaseHost = process.env.FIREBASE_AUTH_EMULATOR_HOST;
  const emulatorUrl = 'http://' + firebaseHost;
  connectAuthEmulator(auth, emulatorUrl);
}

createApp({
  components: {
    PointsView,
    MapsView,
    DesignView,
    AnalyticsView,
    EventsView,

    // Account
    AccountLoginView,
    AccountManageView,
    AccountActionsView,
    AccountPasswordChangeView,
    AccountMfaSettingView,
  }
}).use(vuetify)
  .mount('#app-wrap')

// TODO: Vue3化の過程で一時的にコメントアウト
// .filter('round', function (val) {
//   if (isNaN(parseFloat(val))) return null;
//   return Math.round(val);
// })
// .filter('toLocaleString', function (val) {
//   if (isNaN(parseFloat(val))) return null;
//   // return String(Math.round(val)).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
//   return val.toLocaleString();
// });

import '../styles/style.scss'
