<template>
  <v-row
    class="fill-height py-8"
    align-content="center"
    justify="center"
  >
    <v-col cols="6">
      <v-progress-linear
        color="primary"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
    </v-col>
  </v-row>
</template>
