export default {
  map: null,

  // 初期化
  // Args:
  //   lat (float): 初期状態で表示する中心緯度
  //   lat (float): 初期状態で表示する中心軽度
  //   zoom (int): 初期状態で表示するズームレベル
  //   key_name (str): 緯度経度をローカルストレージに保存する際のキー（ユーザ名等を入れる）
  init(lat, lng, zoom, key_name) {
    const self = this

    let default_lat = lat ? lat : 35.6812362
    let default_lng = lng ? lng : 139.7671248
    let default_zoom = zoom ? zoom : 16


    const storage_key = 'map:' + (key_name || '') + ':' + location.pathname;

    if (localStorage) {
      var value = localStorage.getItem(storage_key);
      if (value) {
        var data = value.split(',')
        default_lat = parseFloat(data[0]);
        default_lng = parseFloat(data[1]);
        default_zoom = parseInt(data[2]);
      }
    }

    const latlng = new google.maps.LatLng(default_lat, default_lng);

    const mapOptions = {
      zoom: default_zoom,
      center: latlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      clickableIcons: false,
      mapTypeControl: false,
      streetViewControl: true,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM
      },
      fullscreenControl: false,
      scaleControl: true,
      keyboardShortcuts: false
    };
    const el = document.getElementById('map_canvas');
    this.map = new google.maps.Map(el, mapOptions);

    this.geocoder = new google.maps.Geocoder();

    this.infowindow = new google.maps.InfoWindow();
    // this.places = new google.maps.places.PlacesService(this.map);


    google.maps.event.addListener(this.map, 'idle', function() {
      if (localStorage) {
        var center = self.map.getCenter();
        var zoom = self.map.getZoom();
        var value = center.lat() + ',' + center.lng() + ',' + zoom
        localStorage.setItem(storage_key, value);
      }
      console.log(center.lat(), center.lng(), zoom);
    });

    this.msg_el = document.createElement('div')
    this.msg_el.classList.add('map-message')
    this.msg_el.innerHTML = ''
    this.map.getDiv().appendChild(this.msg_el)
  },

  fitBounds(bounds, max_zoom) {
    if (!max_zoom) {
      this.map.fitBounds(bounds);
      return;
    }

    var zoom = this.getZoomByBounds(bounds);
    if (zoom > max_zoom) {
        zoom = max_zoom;
    }
    this.map.panTo(bounds.getCenter());
    this.map.setZoom(zoom);
    return zoom;
  },

  getZoomByBounds(bounds){
    var mapType = this.map.mapTypes.get(this.map.getMapTypeId());
    if (!mapType) {
      return;
    }
    var maxZoom = mapType.maxZoom ? mapType.maxZoom : 21;
    var minZoom = mapType.minZoom ? mapType.minZoom : 0;
    var northEast = this.map.getProjection().fromLatLngToPoint(bounds.getNorthEast());
    var southWest = this.map.getProjection().fromLatLngToPoint(bounds.getSouthWest());
    var worldCoordWidth = Math.abs(northEast.x - southWest.x);
    var worldCoordHeight = Math.abs(northEast.y - southWest.y);
    var fitPad = 30;
    for (var zoom = maxZoom; zoom >= minZoom; --zoom) {
      if (worldCoordWidth * (1 << zoom) + 2 * fitPad < this.map.getDiv().clientWidth && worldCoordHeight * (1 << zoom) + 2 * fitPad < this.map.getDiv().clientHeight) {
        return zoom;
      }
    }
    return 0;
  },

  location_marker: null,
  watch_id: null,

  getCurrentLocation() {
    const self = this
    if (self.watch_id) {
      if (self.location_marker) {
        self.map.panTo(self.location_marker.getPosition());
      }
    }
    function success(position) {
      if (position.coords.accuracy > 100000) {
        return
      }
      var p = new google.maps.LatLng(position.coords.latitude, position.coords.longitude)

      if (!self.location_marker) {
        self.location_marker = new google.maps.Marker({
          icon: {
            'url': '/static/assets/gpsloc.png',
            'size': new google.maps.Size(34, 34),
            'scaledSize': new google.maps.Size(17, 17),
            'origin': new google.maps.Point(0, 0),
            'anchor': new google.maps.Point(8, 8)
          },
          position: p,
          map: self.map
        });
        self.map.panTo(p);

      } else {
        self.location_marker.setPosition(p);
      }
    }
    function error(error) {
    }
    self.watch_id = navigator.geolocation.watchPosition(success, error, {
      enableHighAccuracy: true
    });
  },


  showMessage(message) {
    this.msg_el.innerHTML = message
    this.msg_el.style.display = 'block'
  },

  hideMessage() {
    this.msg_el.innerHTML = ''
    this.msg_el.style.display = 'none'
  },


  fromLatLngToPoint(lat, lng) {
    const projection = this.map.getProjection()

    const bounds = this.map.getBounds()
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();

    const p1 = projection.fromLatLngToPoint(new google.maps.LatLng(ne.lat(), sw.lng()));
    const p2 = projection.fromLatLngToPoint(new google.maps.LatLng(lat, lng))

    const scale = Math.pow(2, this.map.getZoom());

    return {
      x: (p2.x - p1.x) * scale,
      y: (p2.y - p1.y) * scale
    }
  }

};
