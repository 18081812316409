<template>
  <v-form ref="emailVelification" v-model="valid" @submit.prevent >
    <h4 class="content-title mb-4">
      メールアドレスの確認が必要です。
    </h4>
    <div class="mb-2">
      登録されたメールアドレスに認証用メールを送信しました。<br/>
      メールを確認し、「次へ」を押してください。
    </div>
    <div class="accountform text-right" justify="end">
      <v-spacer></v-spacer>
      <v-btn
        class="mr-2"
        type="submit"
        color="grey lighten-5"
        @click="resendEmailVerification"
        :disabled="isLoading"
        depressed
      >
        確認メール再送信
      </v-btn>
      <v-btn
        type="submit"
        color="primary"
        @click="checkEmailVerification"
        depressed
      >
        次へ
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { sendEmailVerification } from "firebase/auth";
import AlertService from '@/services/alertservice'

export default {
  emits: [
    'emailVerified'
  ],
  props: {
    user: {
      type: Object,
      required: true,
    }
  },
  mixins: [],
  components: {},
  data() {
    return {
      valid: true,              // フォーム入力内容が正常か
      isLoading: false,         // 読み込み中フラグ
    }
  },
  mounted() {
    sendEmailVerification(this.user)
  },
  methods: {
    resendEmailVerification() {
      this.isLoading = true
      sendEmailVerification(this.user)
      const message = '認証用メールを送信しました'
      AlertService.showSuccess(message)
      setTimeout(()=>{
        this.isLoading = false
      }, 3000)
    },

    async checkEmailVerification() {
      // メールアドレスの確認が済んでいればMFAの登録に移動する
      this.isLoading = true
      await this.user.reload()
        .finally(()=>{
          this.isLoading = false
        })
      if(this.user.emailVerified) {
        this.$emit('emailVerified')
      } else {
        const message = 'メールアドレスの確認が完了していません、\nメールをご確認ください'
        AlertService.showError(message)
      }
    },
  },
}
</script>

<style lang="scss">
</style>
