<template>
  <div class="account-actions-view">
    <v-card-title class="justify-center">{{ title }}</v-card-title>
    <v-divider class="my-0"></v-divider>
    <v-card-text>
      <div class="accountform">
        <ResetPassword
          v-if="resetPassword"
          :oobCode="oobCode"
        />
        <VerifyEmail
          v-else-if="verifyEmail"
          :oobCode="oobCode"
        />
        <RevertSecondFactor
          v-else-if="revertSecondFactorAddition"
          :oobCode="oobCode"
        />
        <RecoverEmail
          v-else-if="recoverEmail"
          :oobCode="oobCode"
        />
        <div v-else>
          パラメータの読み込みに失敗しました<br/>
          URLをご確認ください
        </div>
      </div>
    </v-card-text>
    <Alert></Alert>
  </div>
</template>

<script>

import Alert from '@/components/Alert'
import ResetPassword from '@/components/firebase/emailActions/ResetPassword'
import VerifyEmail from '@/components/firebase/emailActions/VerifyEmail'
import RecoverEmail from '@/components/firebase/emailActions/RecoverEmail'
import RevertSecondFactor from '@/components/firebase/emailActions/RevertSecondFactor'

const MODES = {
  RESET_PASSWORD: 'resetPassword',
  VERIFY_EMAIL: 'verifyEmail',
  RECOVER_EMAIL: 'recoverEmail',
  REVERT_SECOND_FACTOR_ADDITION: 'revertSecondFactorAddition',
}

export default {
  components: {
    Alert,
    ResetPassword,
    VerifyEmail,
    RevertSecondFactor,
    RecoverEmail,
  },
  created() {
    const queryString = window.location.search
    const params = new URLSearchParams(queryString)
    this.mode = params.get('mode')
    this.oobCode = params.get('oobCode')
  },
  mounted() {
    // 該当するmodeがなければリダイレクトする
    // const hasMode = Object.values(MODES).indexOf(this.mode) != -1
    // if(!hasMode) {
    //   location.href = '/'
    // }
  },
  data() {
    return {
      mode: '',
      oobCode: '',
    }
  },
  computed: {
    resetPassword() { return this.mode == MODES.RESET_PASSWORD },
    verifyEmail() { return this.mode == MODES.VERIFY_EMAIL },
    recoverEmail() { return this.mode == MODES.RECOVER_EMAIL },
    revertSecondFactorAddition() { return this.mode == MODES.REVERT_SECOND_FACTOR_ADDITION },
    title() {
      if(this.resetPassword) {
        return 'パスワード再設定'
      } else if (this.verifyEmail){
        return 'メールアドレス確認'
      } else if (this.revertSecondFactorAddition) {
        return '二段階認証の取り消し'
      }
      return ''
    }
  },
  methods: {
  }
}
</script>
<style lang="scss">
.account-actions-view {
}
</style>
