import RestService from './restservice'

export default class PointSerice extends RestService {
  constructor(url) {
    super(url);
  }

  async isKeyExist(key) {
    const params = {key: key}
    return this.httpclientWrapper(this.httpclient.get(this.API_URL, {params}).then(
      response => {
        if (response.data.total > 0) return true
        return false
      }
    ))
  }

  async uploadImage(params) {
    this.isLoading = true;
    return this.httpclient.postFile('/api/images/', params)
      .finally(()=> {
        this.isLoading = false;
      })
  }

  async importCsv(params) {
    try{
      this.isLoading = true
      const response = await this.httpclient.postFile(`${this.API_URL}import-csv`, params)
      .finally(() => {this.isLoading = false})
      return response
    } catch(error) {
      return error.response
    }
  }
  async exportCsv(params) {
    return this.httpclient.get('/api/member-points/export', {params})
  }

  async getImportData() {
    return this.httpclientWrapper(this.httpclient.postFile(`${this.API_URL}import-data`).then(
      response => {
        return response.data
      }
    ))
  }
  async importStart(params) {
    try{
      this.isLoading = true
      const response = await this.httpclient.post(`${this.API_URL}import-start`, params)
        .finally(() => {this.isLoading = false})
      return response
    } catch(error) {
      return error.response
    }
  }
  async importCancel(params) {
    try{
      this.isLoading = true
      const response = await this.httpclient.postFile(`${this.API_URL}import-cancel`, params)
      .finally(() => {this.isLoading = false})
      return response
    } catch(error) {
      return error.response
    }
  }

}