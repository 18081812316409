<template>
  <v-dialog v-model="isActive" max-width="450" scrollable persistent>
    <v-card>
      <v-card-title>{{title}}</v-card-title>
        <v-card-text>
          {{message}}
        </v-card-text>
        <v-card-text class="d-flex justify-end">
          <v-btn variant="tonal" @click="onCancel">キャンセル</v-btn>
          <v-btn class="ml-2" :color="color" variant="flat" @click="onOk">OK</v-btn>
        </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '確認'
    },
    message: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    },
    okLable: {
      type: String,
      default: 'OK'
    },
    cancelLabel: {
      type: String,
      default: 'キャンセル'
    },
    ok: {
      type: Function,
      default: () => {}
    },
    cancel: {
      type: Function,
      default: () => {}
    },

  },
  data() {
    return {
      isActive: false
    }
  },
  created() {
    this.isActive = true;
  },
  methods: {
    onOk() {
      this.ok()
      this.destroy()
    },
    onCancel() {
      this.cancel()
      this.destroy()
    },
    destroy() {
      this.isActive = false

      // 即座にdomを削除するとtransitionする前に消えてしまうので、200ms待つ
      setTimeout(() => {
          this.$el.parentNode.removeChild(this.$el)
          // TODO: $destroyはvue3では無し、マウント元が消えればdestroyは不要か？
          // this.$destroy()
      }, 200)
    }
  },
}
</script>
