<template>
  <div class="account-manage-view" v-if="auth">
    <Console
      ref="console"
      :service="restService"
      :title="title"
      :itemName="itemName"
      :fields="fields"
      :tableActions="tableActions"
      :rowClassConditions="rowClassConditions"
      :createdCallback="showCreatedUser"
    >
      <template #form="{formData, formHandler}">
        <AccountForm :formData="formData" :formHandler="formHandler" />
      </template>
    </Console>

    <CreatedUserDialog v-model="showCreatedUserDialog" :user="createdUser" />
  </div>
</template>

<script>
import RestSerice from '@/services/restservice';
import Console from '@/components/console/Console';
import CreatedUserDialog from '@/components/manage/CreatedUserDialog';
import AccountForm from './form';
import { getAuth } from '@firebase/auth';

let self = null
export default {
  components: {
    Console,
    AccountForm,
    CreatedUserDialog,
  },
  created() {
    self = this
  },
  mounted() {
  },
  data() {
    return {
      auth: getAuth(),
      restService: new RestSerice('/account/api/users/'),
      title: 'ユーザ管理',
      itemName: 'ユーザ',

      fields: [
        { id: 1, label: 'メールアドレス', key: 'email', type: 'string', sortable: true},
        { id: 2, label: 'ステータス', key: 'is_active', type: 'boolean', sortable: true, boolText: ['無効', '有効']},
        // { id: 3, label: '最終ログイン日時', key: 'last_login', type: 'datetime', sortable: true},
        // { id: 4, label: 'ユーザ名', key: 'username', type: 'string', sortable: true},
        { id: 3, label: '権限', key: 'is_staff', type: 'boolean', sortable: true, boolText: ['ユーザ', '管理者'] },
      ],
      tableActions: [
        {label: '編集', action: 'edit', condition: row=>row.columns.email !== this.auth?.currentUser?.email},
        {label: '削除', action: 'delete', condition: row=>row.columns.email !== this.auth?.currentUser?.email},
        {
          label: '自分は編集できません',
          action: 'custom',
          condition: row=>row.columns.email === this.auth?.currentUser?.email,
          func: ()=>{}
        },
      ],
      rowClassConditions: [
        {condition: row => row.columns.is_active, className: 'active-user'},
        {condition: row => !row.columns.is_active, className: 'inactive-user'},
      ],

      showCreatedUserDialog: false,
      createdUser: {}
    }
  },
  methods: {
    showCreatedUser(user)  {
      this.createdUser = user
      this.showCreatedUserDialog = true
    },


  }
}
async function reinvite(id) {
  if (window.confirm('アカウントを登録するためのメールを再送信します。よろしいですか？')) {
    self.restService.create({id})
  }
}
</script>
<style lang="scss">
.account-manage-view {
  .inactive-user {
    color: #aaa;
  }
}
</style>
