import {createVuetify} from 'vuetify'
import { ja } from 'vuetify/locale';
import { aliases, md } from 'vuetify/iconsets/md'
import { mdi } from "vuetify/iconsets/mdi";
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/styles'

/**
 * NOTE: vuetify3.1では v-data-table がアルファ版なのでlabsから使用する
 * https://next.vuetifyjs.com/en/labs/introduction/
 */
import { VDataTable } from 'vuetify/labs/VDataTable'

/**
 * FIXME: おそらくこのままだとビルド時に不要なcomponentsが含まれるが
 * componentsが無い場合、半端な状態でstyleが読まれレイアウトが崩れるのでひとまずcomponents丸ごと入れている
 */

const opts = {
  components: {
    ...components,
    VDataTable
  },
  directives,
  theme: {
    defaultTheme: 'default',
    themes: {
      default: {
        dark: false,
        colors: {
          primary: '#7fbc39',
          secondary: '#424242',
          accent: '#82B1FF',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107',
          anchor: "#9e9e9e",
          anchor_hov: "#5e5e5e",

          surface: '#fff',
          'primary-darken-1': '#3700B3',
          'secondary-darken-1': '#03DAC5',
          'on-primary': '#fff'
        },
      }
    },
  },
  global: {
    ripple: false,
    elevation: 0,
  },
  defaults: {
    // コンポーネントの初期値設定
    VTextField: {
      variant: 'outlined',
      density: 'compact'
    },
    VBtn: {
      elevation: 0,
      flat: true,
    }
  },
  locale: {
    locale: 'ja',
    fallback: 'ja',
    messages: { ja }
  },
  icons: {
    defaultSet: 'md',
    aliases,
    sets: {
      md,
      mdi,
    }
  },
}

export default createVuetify(opts)