<template>
  <section class="analytics-view">
    <div class="md-padding theme-settings">
      <v-row class="justify-space-between">
        <v-col cols="auto">
          <div class="filter-container">
            <range-selector :range="range" @onRangeChanged="onRangeChanged"></range-selector>
          </div>
        </v-col>
      </v-row>
      <div class="ranking-container">
        <v-card class="ranking-card" :width="width" v-for="ranking in rankings" :key="ranking.name">
          <v-card-title class="ranking-title">{{ ranking.name }}</v-card-title>
          <table class="ranking-records">
            <tbody>
              <template v-if="rankingRecords[ranking.value].length != 0">
                <tr class="ranking-tr" v-for="(record, i) in rankingRecords[ranking.value]" :key="record.name">
                  <td class="ranking-td" :class="getRankColor(calculateRank(i))">{{ calculateRank(i) }}</td>
                  <td class="ranking-td">{{ record.name }}</td>
                  <td class="ranking-td">{{ record.count }}</td>
                </tr>
              </template>
              <template v-else>
                  <tr class="d-flex align-center justify-center fill-height loading-box" v-if="loading">
                    <v-progress-circular
                      color="grey-lighten-4"
                      indeterminate
                    ></v-progress-circular>
                  </tr>
                  <td class="empty-record" v-else>イベントがありません</td>
              </template>
            </tbody>
          </table>
        </v-card>
      </div>
      <v-row justify="end">
        <v-col cols="auto">
          <v-btn variant="text" depressed color="primary" :href="toEventPage">全てのイベントを見る</v-btn>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import AnalyticsService from '@/services/analyticsservice'
import RangeSelector from '@/components/RangeSelector'

export default {
  components: {
    RangeSelector,
  },
  data() {
    return {
      range: {
        start: '',
        end: '',
      },
      restService: new AnalyticsService(`/api/maps/${storelocator.map.id}/analytics/`),
      page: 1,
      rankingRecords: this.initialRankings(),
      loading: false,
      rankings: [
        {name: '人気の商品', value: 'SelectProduct'},
        {name: '人気の店舗', value: 'SelectMarker'},
      ],
      selectedEvent: 'SelectProduct',
      windowWidth: 0,
    }
  },
  computed: {
    width() {
      if(this.windowWidth < 1280) return '100%'
      const marginPercentage = 2 //マージンを変える場合はここを調整
      const totalMarginPercentage = (this.rankings.length - 1) * marginPercentage
      const totalWidthPercentage = 100 - totalMarginPercentage
      return `${totalWidthPercentage / this.rankings.length}%`
    },
    toEventPage() {
      if(this.range.start && this.range.end) {
        return `/console/maps/${storelocator.map.id}/analytics/events/?start=${this.range.start}&end=${this.range.end}`
      }
      return `/console/maps/${storelocator.map.id}/analytics/events`
    }
  },
  created() {
    window.addEventListener("resize", this.updateWindowWidth)
    this.updateWindowWidth()
  },
  methods: {
    onRangeChanged(range) {
      for (const key in range) {
        this.range[key] = range[key]
      }
      if(this.range.start && this.range.end) {
        this.runAsyncHandler(this.fetchRankings)
      }
    },
    async fetchRankings() {
      this.rankingRecords = this.initialRankings()
      const params = {
        start: this.range.start,
        end: this.range.end,
      }
      const response = await this.restService.fetchRankings(params);
      if(response.status != 200) {
        this.rankingRecords = this.initialRankings()
        return
      }
      this.rankingRecords = response.data
    },
    async onClickExport() {
      window.open(`${this.restService.API_URL}export?start=${this.range.start}&end=${this.range.end}&event_name=${this.selectedEvent}`)
    },
    calculateRank(index) {
      return index + 1
    },
    getRankColor(index) {
      return index == 1 ? 'first'
        : index == 2 ? 'second'
        : index == 3 ? 'third'
        : ''
    },
    async runAsyncHandler(handler) {
      this.loading = true
      await handler()
      this.loading = false
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth
    },
    initialRankings() {
      return {
        SelectProduct: [],
        SelectMarker: [],
      }
    },
    initialMainDate() {
      return  {
        start: '',
        end: '',
      }
    },
  }
}
</script>

<style lang="scss" scoped>

.a {
  word-break: keep-all;
}
.code-tab {
  min-height: 400px;
}

.filter-container {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 24px;
}

.actions {
  width: 100%;
  padding: 1rem;
  background-color: #f7f7f7;
  display: flex;
  justify-content: end;
  z-index: 100;
}
.ranking-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  .ranking-card {
    margin-bottom: 24px;
  }

  .ranking-title {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }

  .ranking-records {
    border-spacing: 0;
    font-size: 12px;
    width: 100%;
  }

  .ranking-tr {
    height: 36px;
  }

  .ranking-td {
    padding: 0 16px;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    border-spacing: 0;
    text-align: center;
  }

  .ranking-td:first-child {
    width: 61px;
  }

  .ranking-td:first-child {
    word-break: keep-all;
    width: 51px;
  }
  .ranking-td:last-child {
    word-break: keep-all;
    width: 51px;
    text-align: right;
  }

  td:not(.ranking-td:first-child):not(.ranking-td:last-child) {
    text-align: left;
  }

  input[type="date"]{
    width: 100%;
      
    /*忘れずに書きましょう。*/
    position: relative;
  }

  input[type=date]::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .first {
    background-color: #ffde82;
    border-bottom: initial;
    text-align: center;
  }

  .second {
    background-color: #e2d9d9;
    border-bottom: initial;
  }

  .third {
    background-color: #fbbf74;
    border-bottom: initial;
  }

  .loading-box {
    padding: 64px 32px;
  }

  .empty-record {
    padding: 64px;
  }

}

.select-box {
  overflow: hidden;
  position: relative;
  margin: 0 32px;
}

.form-control {
  border: solid 1px rgba(0,0,0,0.12);
  border-radius: 3px;
  padding: 5px 8px;
  background-color: rgba(255, 255, 255, 0);
  position: relative;
  z-index: 2;
  padding-right: 32px;
}

.form-control:focus {
  border-color: rgba(0,0,0,0.12);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.accuracy {
  color: #757575;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 8px;
  display: flex;
  align-items: center;
  width: max-content;
  font-size: 12px
}


@media screen and (max-width: 1280px){
  .ranking-container {
    flex-wrap: wrap;
  }
}

</style>