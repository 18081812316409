<template>
  <section>
    <Relogin v-if="needRelogin" />
    <v-form v-model="valid" ref="passwordform" v-else-if="!completed">
      <div class="accountform pt-4 mb-4">
        <v-text-field
          v-model="currentPassword"
          id="current-password"
          autocomplete="current-password"
          density="compact"
          type="password"
          label="現在のパスワード"
          :rules="[rules.required, rules.min6]"
          color="primary"
        ></v-text-field>
        <v-text-field
          v-model="newPassword"
          id="new-password"
          autocomplete="new-password"
          density="compact"
          type="password"
          label="新しいパスワード"
          :rules="[rules.required, rules.min6, rules.invalidPasswordCharactor]"
          color="primary"
        ></v-text-field>
        <v-text-field
          v-model="newPasswordConfirm"
          id="new-password-confirm"
          autocomplete="new-password"
          density="compact"
          type="password"
          label="新しいパスワード（再入力）"
          :rules="[rules.required, rules.min6, rules.confirmPassword]"
          color="primary"
        ></v-text-field>

        <Recaptcha ref="recaptcha" invisible />

        <v-btn
          block
          color="primary"
          depressed
          large
          @click="onChangePassword"
          :disabled="!valid"
          :loading="loading"
        >
          パスワード変更
        </v-btn>

        <v-btn
          class="mt-4"
          depressed
          block
          text
          large
          @click="historyBack()"
        >
          戻る
        </v-btn>
      </div>
    </v-form>
    <div v-else>
      パスワードを再設定しました。
      <div class="mt-4">
        <v-btn variant="text" color="primary" href="/account/user">
          アカウント情報
        </v-btn>
      </div>
    </div>

    <MultiFactorDialog
      v-model="mfaDialog"
      :resolver="resolver"
      :next="changeFirebasePassword"
      :recaptchaVerifier="recaptchaVerifier"
    />
    <Alert></Alert>
  </section>
</template>

<script>
/**
 * Firebase Auth使用時のパスワード再設定view
 * パスワード変更前に、Email+Password(+MFA)で再認証を行う
 * TODO: OAuthでログインした場合はパスワード変更不可能とする
 */

import {
  getAuth,
  onAuthStateChanged,
  getMultiFactorResolver,
  EmailAuthProvider,
  reauthenticateWithCredential,
  signInWithCredential,
  updatePassword,
  AuthErrorCodes,
  RecaptchaVerifier,
} from "firebase/auth";

import Alert from '@/components/Alert'
import MultiFactorDialog from '@/components/firebase/MultiFactorDialog'
import Recaptcha from '@/components/firebase/Recaptcha'
import Relogin from '@/components/firebase/Relogin'
import rules from "@/utils/rules"
import { showErrorMessageByCode } from '@/utils/firebase'

const REAUTH_RESULT = {
  SUCCESS: 'SUCCESS',
  MFA_REQUIRED: 'MFA_REQUIRED',
  ERROR: 'ERROR',
}
let self = null
export default {
  components: {
    MultiFactorDialog,
    Recaptcha,
    Relogin,
    Alert,
  },
  created() {
    self = this
  },
  mounted() {
    this.init()
  },
  data() {
    return {
      auth: getAuth(),
      resolver: {},

      email: '',
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      valid: false,
      rules: {
        ...rules,
        confirmPassword: v => v === this.newPassword || 'パスワードが一致しません',
      },

      loading: false,
      completed: false,
      mfaDialog: false,
      recaptchaVerifier: {},
      needRelogin: false,
    }
  },
  methods: {
    async init() {
      this.recaptchaVerifier = await this.$refs.recaptcha.initRecaptcha()
      onAuthStateChanged(this.auth, ()=> {
        if(this.auth.currentUser) {
          this.email = this.auth.currentUser.email
        } else {
          // Firebase側のログイン情報が無くなった場合は再ログインを促す
          this.needRelogin = true
        }
      })
    },

    async onChangePassword() {
      this.loading = true
      if(this.$refs.passwordform.validate()) {
        const result = await this.reauth()
        if(result === REAUTH_RESULT.SUCCESS) {
          this.changeFirebasePassword()
        } else if(result === REAUTH_RESULT.MFA_REQUIRED) {
          this.mfaDialog = true
        }
      }
    },
    async reauth() {
      // メールアドレスと現在のパスワードで再認証する
      // 二段階認証が設定されている場合はそちらも実行
      const authCredential = EmailAuthProvider.credential(this.email, this.currentPassword.trim())

      if(!this.auth.currentUser) {
        // 何らかの形でFirebaseAuthの認証が失われている場合はreauthではなくsigninする
        return this._signInWithCredential(this.auth.currentUser, authCredential)
      }
      return this._reauthenticateWithCredential(this.auth.currentUser, authCredential)
    },

    async _reauthenticateWithCredential(user, credential) {
      return reauthenticateWithCredential(user, credential)
        .then(()=> {
          // 再認証成功
          return REAUTH_RESULT.SUCCESS
        })
        .catch(error => {
          // 再認証に二段階認証が設定されていると失敗する
          // 二段階認証が要求された場合、errorからMFAのresolverを取得する
          if(error.code == AuthErrorCodes.MFA_REQUIRED) {
            this.resolver = getMultiFactorResolver(this.auth, error)
            return REAUTH_RESULT.MFA_REQUIRED
          } else {
            // それ以外ではエラー表示
            showErrorMessageByCode(error.code)
            return REAUTH_RESULT.ERROR
          }
        })
        .finally(()=>{
          this.loading = false
        })
    },

    async _signInWithCredential() {
      return signInWithCredential(this.email, this.currentPassword.trim())
        .then(()=> {
          return REAUTH_RESULT.SUCCESS
        })
        .catch(error => {
          if(error.code == AuthErrorCodes.MFA_REQUIRED) {
            this.resolver = getMultiFactorResolver(this.auth, error)
            return REAUTH_RESULT.MFA_REQUIRED
          } else {
            showErrorMessageByCode(error.code)
            return REAUTH_RESULT.ERROR
          }
        })
        .finally(()=>{
          this.loading = false
        })
    },

    async changeFirebasePassword(){
      const user = this.auth.currentUser
      if(!user) return
      try {
        await updatePassword(user, this.newPassword)
        this.completed = true
      } catch (e) {
        showErrorMessageByCode(e.code)
      }
      this.loading = false

    },

    historyBack() {
      if(history.length > 1) {
        history.back();
      } else {
        location.href= '/account/user'
      }
    }
  },
}
</script>

<style lang="scss">
</style>
