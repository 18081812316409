<template>
  <section class="analytics-view">
    <div class="md-padding theme-settings">
      <v-row>
        <v-breadcrumbs :items="breadcrumbs">
          <template v-slot:divider>
            <v-icon>chevron_right</v-icon>
          </template>
        </v-breadcrumbs>
      </v-row>

      <v-row class="justify-space-between">
        <v-col cols="auto">
          <div class="filter-container">
            <div class="select-boxs">
              <div class="select-box event">
                <div class="accuracy">▾</div>
                <select v-model="selectedEvent" class="form-control" @change="onFilterChanged" :disabled="loading">
                  <option v-for="e in eventKinds" :value="e.value" :key="e.value">{{ e.name }}</option>
                </select>
              </div>
            </div>
            <range-selector :range="range" @onRangeChanged="onRangeChanged" :disabled="loading"></range-selector>
          </div>
          <div class="filter-container sub" v-if="selectedEvent === 'SelectProduct'">
            <div class="select-boxs">
              <div class="select-box select-product-by">
                <div class="accuracy">▾</div>
                <select v-model="selectedProducBy" class="form-control" @change="onFilterChanged" :disabled="loading">
                  <option v-for="e in filteredSelectProductBys" :value="e.value" :key="e.value">{{ e.name }}</option>
                </select>
              </div>
              <div class="select-box brands" v-if="brands">
                <div class="accuracy">▾</div>
                <select v-model="selectedBrand" class="form-control" @change="onFilterChanged" :disabled="loading">
                  <option v-for="b in brands" :value="b.value" :key="b.value">{{ b.name }}</option>
                </select>
              </div>
              <div class="select-box categories" v-if="categories">
                <div class="accuracy">▾</div>
                <select v-model="selectedCategory" class="form-control" @change="onFilterChanged" :disabled="loading">
                  <option v-for="c in categories" :value="c.value" :key="c.value">{{ c.name }}</option>
                </select>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="auto">
          <v-btn depressed color="primary"
            @click="onOpenExportClicked" :disabled="!range.start || !range.end || loading">
            エクスポート
          </v-btn>
        </v-col>
      </v-row>

      <div class="ranking-container">
        <v-card width="100%">
          <table class="ranking-records">
            <tbody>
              <template v-if="ranking?.length > 0">
                <tr class="ranking-tr" v-for="(record, i) in ranking" :key="record.name">
                  <td class="ranking-td" :class="getRankColor(calculateRank(i))">{{ calculateRank(i) }}</td>
                  <td class="ranking-td">{{ record.name }}</td>
                  <td class="ranking-td">{{ record.count }}</td>
                </tr>
              </template>
              <template v-else>
                  <tr class="d-flex align-center justify-center fill-height loading-box" v-if="loading">
                    <v-progress-circular
                      color="grey-lighten-4"
                      indeterminate
                    ></v-progress-circular>
                  </tr>
                  <td class="empty-record" v-else>イベントがありません</td>
              </template>
            </tbody>
          </table>
        </v-card>
      </div>
      <p class="alert" v-if="ranking?.length === 100">
        ※100件まで表示しています、エクスポートすると全ての結果を確認できます
      </p>
    </div>

    <ConsoleModal ref="exportModal" v-model="exportModal.opened" title="エクスポート">
      <v-card-text>
        <LoadingOverlay :isActive="isParsing" message="エクスポート処理中" />
        <v-row class="mt-2">
          <v-col>現在の絞り込み条件でイベントをエクスポートします</v-col>
        </v-row>

        <v-row class="export-conditions mt-4">
          <v-col cols="auto">
            <div class="select-boxs">
              <div class="select-box">
                <select v-model="selectedEvent" class="form-control" disabled>
                  <option v-for="e in eventKinds" :value="e.value" :key="e.value">{{ e.name }}</option>
                </select>
              </div>

              <div class="select-box" v-if="selectedProducBy">
                <select v-model="selectedProducBy" class="form-control" disabled>
                  <option v-for="e in filteredSelectProductBys" :value="e.value" :key="e.value">{{ e.name }}</option>
                </select>
              </div>
              <div class="select-box brands" v-if="selectedBrand">
                <select v-model="selectedBrand" class="form-control" disabled>
                  <option v-for="b in brands" :value="b.value" :key="b.value">{{ b.name }}</option>
                </select>
              </div>
              <div class="select-box categories" v-if="selectedCategory">
                <select v-model="selectedCategory" class="form-control" disabled>
                  <option v-for="c in categories" :value="c.value" :key="c.value">{{ c.name }}</option>
                </select>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row class="export-type mt-4">
          <v-col>
            <v-radio-group class="mt-0" inline v-model="exportModal.type" hide-details>
              <v-radio label="合計" value="合計" color="primary"></v-radio>
              <v-radio label="日別" value="日別" color="primary"></v-radio>                
            </v-radio-group>
          </v-col>
          <v-col class="aggregate-by-id">
            <v-checkbox
              class="mt-0"
              v-model="exportModal.aggregate_by_id"
              label="ID単位で集計する"
              color="primary"
              hide-details>
            </v-checkbox>
          </v-col>
        </v-row>

        <v-row class="export-range">
          <v-col>
            <range-selector :range="exportModal" @onRangeChanged="onModalRangeChanged" @dateError="modalDateErrorMessage = $event"></range-selector>
          </v-col>
        </v-row>

      </v-card-text>
      <v-divider class="mt-0"></v-divider>
      <v-card-actions>
        <div class="inport-controls d-flex align-center justify-space-between">
          <div class="flex-grow-1">
            <span class="error--text" v-if="errorMessage">{{errorMessage}}</span>
          </div>
          <div>
            <v-btn class="ml-2" variant="tonal" @click="onCloseExportClicked">キャンセル</v-btn>
            <v-btn class="ml-2" variant="flat" color="primary" @click="onExportClicked" :disabled="!exportModal.start || !exportModal.end || !!modalDateErrorMessage">エクスポート</v-btn>
          </div>
        </div>
      </v-card-actions>
    </ConsoleModal>
  </section>
</template>

<script>
import AnalyticsService from '@/services/analyticsservice'
import ConsoleModal from '@/components/console/ConsoleModal'
import RangeSelector from '@/components/RangeSelector'

export default {
    components: {
      ConsoleModal,
      RangeSelector,
    },
  data() {
    return {
      breadcrumbs: [
        {
          title: 'ダッシュボード',
          disabled: false,
          href: `/console/maps/${storelocator.map.id}/analytics/`,
        },
        {
          title: '全てのイベント',
          disabled: true,
        },
      ],
      page: 1,
      loading: false,
      restService: new AnalyticsService(`/api/maps/${storelocator.map.id}/analytics/`),
      range: {
        start: '',
        end: '',
      },
      // 期間の更新がされたときのみブランド、カテゴリを更新したいのでその判定用、今後の他にふえることも想定しておく
      changed: {
        range: false
      },
      ranking: this.initialRanking(),
      selectedEvent: 'SelectProduct',
      eventKinds: [
        {name: '人気の商品', value: 'SelectProduct'},
        {name: '人気の店舗', value: 'SelectMarker'},
      ],
      selectedProducBy: '',
      selectProductBys: [
        {name: '全ての検索方法', value: ''},
        {name: '商品ページから', value: '製品ページ'},
        {name: 'ブランドページから', value: 'ブランド | 製品ページ'},
        {name: 'カテゴリページから', value: 'カテゴリー | 製品ページ'},
        {name: 'キーワードで探す', value: 'キーワード'},
        {name: 'ブランドから探す', value: 'ブランド'},
        {name: 'カテゴリから探す', value: 'カテゴリー'},
      ],
      selectedBrand: '',
      brands: this.initialBrands(),
      selectedCategory: '',
      categories: this.initialCategories(),
      exportModal: {
        opened: false,
        type: '',
        start: '',
        end: '',
        aggregate_by_id: false,
        additional_params: {
        }
      },
      modalDateErrorMessage: '',
    }
  },
  computed: {
    filteredSelectProductBys() {
      return this.selectProductBys.filter(by => {
        return !by.value || storelocator.analytics.product_by_items.includes(by.value)
      })    
    },
    modalDateError() {
      if(new Date(this.exportModal.end).getDate() - new Date(this.exportModal.start).getDate() < 2
        || !this.exportModal.start
        || !this.exportModal.end) {
        return '期間を1日以上選択してください'
      }
      return ''
    }
  },
  created() {
    this.parseQueryString()
  },
  methods: {
    applyRange(newRange, range) {
      for (const key in newRange) {
        range[key] = newRange[key]
      }
    },
    onRangeChanged(newRange) {
      console.info('onRangeChanged', newRange)
      this.changed.range = true
      this.applyRange(newRange, this.range)
      this.onFilterChanged()
    },
    onModalRangeChanged(newRange) {
      this.applyRange(newRange, this.exportModal)
    },
    onFilterChanged() {
      if(this.range.start && this.range.end) {
        this.update()
      }
    },
    async fetchRanking() {
      this.ranking = this.initialRanking()
      const response = await this.restService.fetchRanking({
        start: this.range.start,
        end: this.range.end,
        event_name: this.selectedEvent,
        select_product_by: this.selectedProducBy,
        select_brand: this.selectedBrand,
        select_category: this.selectedCategory,
      })
      this.ranking = response.data[this.selectedEvent]
    },
    async fetchBrands() {
      this.brands = this.initialBrands()
      const response = await this.restService.fetchBrands({
        start: this.range.start,
        end: this.range.end,
      })

      const response_brands = response.data.items.map(item => {
        return {
          name: item.name,
          value: item.name,
        }
      })

      this.brands = [{
        name: '全てのブランド',
        value: '',
      }, ...response_brands]
    },
    async fetchCategories() {
      this.categories = this.initialCategories()
      const response = await this.restService.fetchCategories({
        start: this.range.start,
        end: this.range.end,
      })
      
      const response_categories = response.data.items.map(item => {
        return {
          name: item.name,
          value: item.name,
        }
      })

      this.categories = [{
        name: '全てのカテゴリ',
        value: '',
      }, ...response_categories]
    },
    onOpenExportClicked() {
      this.exportModal.opened = true
      this.exportModal.type = '合計'
      this.exportModal.start = this.range.start
      this.exportModal.end = this.range.end
    },
    onCloseExportClicked() {
      this.exportModal.opened = false
    },
    async onExportClicked() {
      let url = `${this.restService.API_URL}export?start=${this.exportModal.start}&end=${this.exportModal.end}&event_name=${this.selectedEvent}&type=${this.exportModal.type}&aggregate_by_id=${this.exportModal.aggregate_by_id}`

      const additional_param_keys = [
        {localKey: 'selectedProducBy', remoteKey: 'select_product_by'},
        {localKey: 'selectedBrand', remoteKey: 'select_brand'},
        {localKey: 'selectedCategory', remoteKey: 'select_category'},
      ]
      additional_param_keys.forEach(keys => {
        if(this[keys.localKey]) {
          url += `&${keys.remoteKey}=${this[keys.localKey]}`
        }
      })

      window.open(url)
      this.exportModal.opened = false
    },
    calculateRank(index) {
      return index + 1 + ((this.page - 1) * 50)
    },
    getRankColor(index) {
      return index == 1 ? 'first'
        : index == 2 ? 'second'
        : index == 3 ? 'third'
        : ''
    },
    async update() {
      this.loading = true

      const promises = [this.fetchRanking()]

      // 期限がかわるとブランド／カテゴリの選択肢も広がるので取り直したい
      if (this.selectedEvent === 'SelectProduct' && this.changed.range) {
        promises.push(this.fetchBrands())
        promises.push(this.fetchCategories())
        promises.push((async () => {
          this.changed.range = false
        })())
      } else {
        this.changed.range = false
      }

      await Promise.all(promises);

      this.loading = false
    },
    parseQueryString() {
      const pairs = location.search.slice(1).split('&')
      for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=')
        const key = decodeURIComponent(pair[0])
        const value = decodeURIComponent(pair[1] || '')
        if(!this.validateDate(value)) {
          return
        }

        if (key) {
          this.range[key] = value
        }
      }
    },
    validateDate(dateStr) {
      // 正規表現パターン: YYYY-MM-DD
      const pattern = /^\d{4}-\d{2}-\d{2}$/
      if (!pattern.test(dateStr)) {
        return false
      }

      const date = new Date(dateStr)
      const dateUnits = dateStr.split('-').map(Number)

      return (
        dateUnits[0] === date.getFullYear() &&
        dateUnits[1] === date.getMonth() + 1 &&
        dateUnits[2] === date.getDate()
      );
    },
    initialBrands() {
      return [{
        name: '読み込み中',
        value: '',
      }]
    },
    initialCategories() {
      return [{
        name: '読み込み中',
        value: '',
      }]
    },
    initialRanking() {
      return []
    },
  }
}
</script>

<style lang="scss" scoped>
.analytics-view {
  .v-breadcrumbs {
    margin: 8px 0 16px 8px;
  }
}

.a {
  word-break: keep-all;
}

.filter-container {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 24px;
  &.sub {
    margin-top: -8px;
    padding-bottom: 32px;
  }
}

.ranking-container {
  display: flex;
  justify-content: space-between;

  .ranking-card {
    width: 49%
  }

  .ranking-title {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }

  .ranking-records {
    border-spacing: 0;
    font-size: 12px;
    width: 100%;
  }

  .ranking-tr {
    height: 36px;
  }

  .ranking-td {
    padding: 0 16px;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    border-spacing: 0;
    text-align: center;
  }

  .ranking-td:first-child {
    width: 61px;
  }

  .ranking-td:first-child {
    word-break: keep-all;
    width: 51px;
  }
  .ranking-td:last-child {
    word-break: keep-all;
    width: 51px;
    text-align: right;
  }

  td:not(.ranking-td:first-child):not(.ranking-td:last-child) {
    text-align: left;
  }

  input[type="date"]{
    width: 100%;
      
    /*忘れずに書きましょう。*/
    position: relative;
  }

  input[type=date]::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .first {
    background-color: #ffde82;
    border-bottom: initial;
    text-align: center;
  }

  .second {
    background-color: #e2d9d9;
    border-bottom: initial;
  }

  .third {
    background-color: #fbbf74;
    border-bottom: initial;
  }

  .loading-box {
    padding: 64px 32px;
  }

  .empty-record {
    padding: 64px;
  }
}

.select-boxs {
  display: flex;
  margin-right: 32px;
  .select-box {
    overflow: hidden;
    position: relative;
    margin-right: 8px;
    select {
      max-width: 200px;
    }
    .accuracy + .form-control {
      padding-right: 28px;
    }
    .accuracy {
      color: #757575;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 8px;
      display: flex;
      align-items: center;
      width: max-content;
      font-size: 12px
    }
  }  
}

.v-dialog {
  .select-boxs {
    .select-box {
      [disabled] {
        border: none;
      }
    }
  }
}

.form-control {
  border: solid 1px rgba(0,0,0,0.12);
  border-radius: 3px;
  padding: 5px 12px;
  background-color: rgba(255, 255, 255, 0);
  position: relative;
  z-index: 2;
  color: rgba(0, 0, 0, 0.6);
}

.form-control:focus {
  border-color: rgba(0,0,0,0.12);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.disabled-color {
  color: #c7c7c7;
}

.modal-date-error {
  position: absolute;
  bottom: 0;
  color: #FF5252;
}

.export-type {
  margin: 0 0 -20px -20px;
  .aggregate-by-id {
    padding: 4px;
  }
}

.export-conditions {
  margin-left: -24px;
}

.alert {
  margin: 12px 0 0 0;
  opacity: .8;
  text-align: right;
}

</style>