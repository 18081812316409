<template>
  <section class="design-view">
    <div v-if="errorMessages.getMapConfig">
      <p class="ma-0 error--text">{{ errorMessages.getMapConfig }}</p>
    </div>
    <div v-else>

      <v-tabs v-model="currentTab">
        <v-tab v-for="tab in tabs" :key="tab.label">{{tab.label}}</v-tab>
      </v-tabs>
      <v-divider class="pb-4"></v-divider>
      <v-window class="mb-4" v-model="currentTab">
        <v-window-item>
          <v-row class="mt-4">
            <v-col>
              <v-text-field density="compact" hide-details label="タイトル" v-model="mapConfig.title" color="primary"></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="useColorSetting">
            <v-col v-if="this.mapConfig.color">
              <label class="mr-6">
                <span class="mr-2">テーマカラー</span>
                <color-picker v-model="mapConfig.color.theme"></color-picker></label>
              <label>
                <span class="mr-2">テキストカラー</span>
                <color-picker v-model="mapConfig.color.text"></color-picker>
              </label>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-tabs v-model="editorTab">
                <v-tab v-for="tab in editorTabs" :key="tab.tabId" :href="`#${tab.tabId}`">{{tab.label}}</v-tab>
              </v-tabs>
              <v-divider class="mb-4"></v-divider>
              <v-window v-model="editorTab" class="code-tab">
                <v-window-item v-for="tab in editorTabs" :key="tab.tabId" :value="tab.tabId">
                  <template v-if="tab.tabId == 'tab_analytics_tag'">
                    <v-select class="my-4" v-model="analyticsPosition" item-title="text" item-value="value" :items="analyticsPositionList" @update:menu="onChangeAnalyticsPosition" label="挿入場所" density="compact" hide-details></v-select>
                  </template>
                  <template v-for="edi in tab.editor">
                    <div v-show="edi.show" class="codemirror-editor" :id="edi.key" :data-mode="edi.mode"></div>
                  </template>
                </v-window-item>
              </v-window>
            </v-col>
          </v-row>
        </v-window-item>

        <v-window-item>準備中</v-window-item>
        <v-window-item>準備中</v-window-item>

        <v-window-item>
          <v-form @submit.prevent="appryMapConfig">
            <v-row>
              <v-col cols="auto">
                現在の設定を選択したマップへ反映します。
              </v-col>
            </v-row>
            <v-row class="justify-start align-center">
              <v-col cols="auto">
                <v-text-field density="compact" hide-details readonly label="現在のマップ" :value="currentMap"></v-text-field>
              </v-col>
              <v-col cols="auto">
                <v-icon>chevron_right</v-icon>
              </v-col>
              <v-col>
                <v-select density="compact" hide-details label="反映先のマップ" v-model="apply_target_id" :items="mapList"></v-select>
              </v-col>
            </v-row>
            <v-row class="align-center">
              <v-col>
                <p v-if="errorMessages.apply" class="ma-0 text-right error--text">{{ errorMessages.apply }}</p>
              </v-col>
              <v-col cols="auto">
                <v-btn depressed color="primary" type="submit">設定を反映</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-window-item>
      </v-window>


      <div v-if="currentTab != 3">
        <v-divider></v-divider>
        <div class="actions d-flex align-center justify-space-between">
          <div class="flex-grow-1">
            <span class="error--text" v-if="errorMessages.setMapConfig">{{errorMessages.setMapConfig}}</span>
          </div>
          <div>
            <v-btn depressed color="primary" type="submit" @click="setMapConfig">公開</v-btn>
          </div>
        </div>
      </div>

    </div>

  </section>
</template>

<script>
import DesignService from '@/services/designservice'

import CodeMirror from 'codemirror'
import 'codemirror/addon/merge/merge.js'
import 'codemirror/addon/merge/merge.js'

import 'codemirror/addon/hint/show-hint.js'
import 'codemirror/addon/hint/xml-hint.js'
import 'codemirror/addon/hint/html-hint.js'
import 'codemirror/addon/fold/foldcode.js'
import 'codemirror/addon/fold/foldgutter.js'
import 'codemirror/addon/fold/brace-fold.js'
import 'codemirror/addon/fold/xml-fold.js'
import 'codemirror/addon/fold/comment-fold.js'
import 'codemirror/addon/fold/indent-fold.js'

import 'codemirror/mode/xml/xml.js'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/css/css.js'

import ConfirmMixin from '@/mixins/confirm'
import MessageMixin from '@/mixins/message'
import ConsoleModal from '@/components/console/ConsoleModal'
import LoadingOverlay from '@/components/LoadingOverlay'
import ColorPicker from '@/components/ColorPicker'
import {_has} from '@/utils/common'

let self

export default {
  components: {
    ConsoleModal,
    LoadingOverlay,
    ColorPicker,
  },
  mixins: [ConfirmMixin, MessageMixin],
  data() {
    return {
      restService: new DesignService(`/api/maps/${storelocator.map.id}/design/`),

      useColorSetting: false,

      currentTab: null,
      tabs: [
        {label: "デザイン"},
        // {label: "マーカー"},
        // {label: "検索条件"},
        // {label: "別マップへ設定反映"},
      ],

      // 選択中のエディタタブ
      editorTab: "tab_header",

      // エディタタブの設定
      editorTabs: [
       {
          tabId: "tab_analytics_tag", label: "追加タグ",
          editor: [
            {key: "analytics_tag_head_start", mode: "text/html" , show: true},
            {key: "analytics_tag_head_end", mode: "text/html" , show: false},
            {key: "analytics_tag_body_start", mode: "text/html", show: false},
            {key: "analytics_tag_body_end", mode: "text/html", show: false}
          ]
        },
      ],

      // 表示中のアナリティクスエディタ
      analyticsPosition: "head_start",

      // アナリティクスエディタ・セレクタの定義
      analyticsPositionList: [
        {text: "head 最前", value: "head_start"},
        {text: "head 最後", value: "head_end"},
        {text: "body 最前", value: "body_start"},
        {text: "body 最後", value: "body_end"}
      ],

      // codemirrorオブジェクトを格納
      editors: {},

      // 登録されているマップの一覧　
      mapList: [],

      apply_target_id: null,
      appryConfirm: false,

      // 読み込んだmapConfig
      mapConfig: {},

      errorMessages: {
        getMapConfig: null,
        setMapConfig: null,
        apply: null
      },
    }
  },
  created() {
    self = this
  },
  async mounted() {
    await this.setMapList()
    await this.getMapConfig()
  },
  computed: {
    customQuery() {
      return {
        text: this.text
      }
    },
    currentMap() {
      return `${storelocator.map.key} ${storelocator.map.name}`
    },
  },
  watch: {
    editorTab: (tabId) => {
      self.onCangeScriptTab(tabId)
    }
  },
  methods: {
    onCangeScriptTab(tabId) {
      const target = this.editorTabs.find(t => t.tabId == this.editorTab)
      if (!target) return

      // TODO タブのTransitionの終了が保続できないので、setTimeoutで遅延
      // codemorrorは別コンポーネントにわける
      setTimeout(() => {
        if (tabId == "tab_analytics_tag") {
          this.onChangeAnalyticsPosition()
        } else {
          this.setEditor(target.editor[0].key)
        }
      }, 500)
    },
    onChangeAnalyticsPosition() {
      const target = this.editorTabs.find((t) => t.tabId == "tab_analytics_tag")
      const key = `analytics_tag_${this.analyticsPosition}`
      target.editor.forEach(edi => {
        if (edi.key == key) {
          edi.show = true
        } else {
          edi.show = false
        }
      })
      if (this.editors[key]) {
        return
      }
      this.$nextTick(() => {
        this.setEditor(key)
      })
    },
    setEditor(key) {
      console.log("set editor")
      const element = document.getElementById(key)
      if (!element) return
      this.initEditor(element)
    },
    initEditor(elm) {
      const key = elm.id
      const editor = this.editors[key]
      if(!editor || !editor.instance) {
        this.createEditorHeader(elm)
      }
      this.setMergeView(elm)
    },

    createEditorHeader(elm) {
      const editorHeader = document.createElement("div")
      const toggleButton = document.createElement("div")
      editorHeader.className = 'cm-header'

      // TODO 単一エディタ表示
      toggleButton.className = 'cm-toggle-btn'
      toggleButton.innerHTML = '表示切替'
      toggleButton.onclick = () => {
        this.toggleEditor(elm)
      }
      editorHeader.appendChild(toggleButton);
      elm.appendChild(editorHeader)
    },

    setMergeView(elm) {
      const key = elm.id
      let value = ''
      if (/analytics_tag/.test(key)) {
        const config_key = key.replace(/analytics_tag_/, "")
        value = this.mapConfig.analytics_tag[config_key] || ''
      } else {
        value = this.mapConfig[key] || ''
      }

      const editor = this.editors[key] || {}
      let orig = value;

      if(editor.instance && editor.type === 'diff') {
        return
      }
      if(editor.orig !== undefined) {
        orig = editor.orig
      } else {
        editor.orig = value
      }
      if(editor.instance) {
        const removeElm = elm.querySelector('.CodeMirror')
        elm.removeChild(removeElm)
      }
      const cm = CodeMirror.MergeView(elm, {
        value: value,
        origRight: orig,
        mode: elm.dataset.mode || null,
        highlightDifferences: true,
        collapseIdential: false,
        revertButtons: false,
        lineNumbers: true,
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"]
      })
      cm.edit.on('changes', (doc) => this.updateConfigItem(doc, key));
      this.editors[key] = {
        ...editor,
        instance: cm,
        type: 'diff'
      }
    },

    setSignleView(elm) {
      const key = elm.id
      let value = ''
      if (/analytics_tag/.test(key)) {
        const config_key = key.replace(/analytics_tag_/, "")
        value = this.mapConfig.analytics_tag[config_key] || ''
      } else {
        value = this.mapConfig[key] || ''
      }
      const editor = this.editors[key]

      if(editor){
        if(editor.type === 'single') {
          return
        }
        const removeElm = elm.querySelector('.CodeMirror-merge')
        elm.removeChild(removeElm)
      }

      const cm = CodeMirror(elm, {
        value: value,
        mode: elm.dataset.mode || null,
        lineNumbers: true,
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"]
      });

      cm.on('changes', (doc) => this.updateConfigItem(doc, key));
      this.editors[key] = {
        ...editor,
        instance: cm,
        type: 'single'
      }
    },

    updateConfigItem (doc, key) {
      const updateValue = doc.getValue()
      if (/analytics_tag/.test(key)) {
        if (this.mapConfig.analytics_tag == null || this.mapConfig.analytics_tag == "") {
          this.mapConfig.analytics_tag = {
            head_start: '',
            head_end: '',
            body_start: '',
            body_end: '',
          }
        }
        const analytics_key = key.replace(/analytics_tag_/, "")
        this.mapConfig.analytics_tag[analytics_key] = updateValue
      } else {
        this.mapConfig[key] = updateValue
      }
    },
    toggleEditor (elm) {
      const editor = this.editors[elm.id]
      if(editor.type === 'diff') {
        this.setSignleView(elm)
      } else {
        this.setMergeView(elm)
      }
    },
    updateOriginals() {
      // 比較元の値を入れ替える
      Object.keys(this.editors).forEach(key => {
        const editor = this.editors[key]
        const textValue = this.mapConfig[key]
        if(editor) {
          if(editor.type === 'diff' && editor.instance) {
            editor.instance.rightOriginal().setOption('value', textValue || '')
          }
          editor.orig = textValue || ''
        }
      })
    },

    setMapList() {
      console.log(storelocator.map)
      this.mapList = storelocator.map.map_list.map(m => {
        return {
          text: `${m.key} ${m.name}`,
          value: m.id
        }
      })

      if (storelocator.map.apply_target_id) {
        this.apply_target_id = storelocator.map.apply_target_id
      }
    },
    async appryMapConfig() {
      const confirm = await this.confirm("設定の反映", "対象のマップに設定を反映してよろしいですか？")
      if (confirm) {
        await this.restService.applyConfig({
          apply_target_id: this.apply_target_id
        })
        if (this.restService.error) {
          this.errorMessages.apply = "エラーが発生したため処理を中断しました"
          return
        }
        this.message("設定を反映しました")
      }
    },
    async getMapConfig() {
      const result = await this.restService.getConfig()
      if (this.restService.error) {
        this.errorMessages.getMapConfig = "デザイン設定の取得に失敗しました"
        return
      }
      this.mapConfig = result.data
      if (this.mapConfig.extra_config == null) {
        this.mapConfig.extra_config = {}
      }

      if (this.mapConfig.analytics_tag == null) {
        this.mapConfig.analytics_tag = {
          "head_start": "",
          "head_end": "",
          "body_start": "",
          "body_end": ""
        }
      }

      if (this.mapConfig.color == null) {
        this.mapConfig.color = {
          theme:  null,
          text:  null,
        }
      }
      this.setEditor(this.editorTab.replace(/tab_/, ""))
      console.log("mapConfig", this.mapConfig)
    },

    async setMapConfig() {
      const confirm = await this.confirm("設定の反映", "現在の設定を公開します。よろしいですか？")
      if (confirm) {
        await this.restService.setConfig(this.mapConfig)
        if (this.restService.error) {
          this.errorMessages.setMapConfig = "エラーが発生したため処理を中断しました"
          return
        }
        this.updateOriginals()
        this.message("設定を反映しました")
      }
    },

    showNotification(message, status='success') {
      this.notifyType = status
      this.notifyMessage = message
      this.showNotify = true
    },
    onCloseModal() {
      this.importLoading = false
      this.clearImportData()
    },
  }
}
</script>

<style lang="scss">
@import "~codemirror/lib/codemirror.css";
@import "~codemirror/addon/merge/merge.css";
@import "~codemirror/addon/hint/show-hint.css";
@import "~codemirror/addon/fold/foldgutter.css";


.code-tab {
  min-height: 400px;
}

.codemirror-editor {
  font-size: 13px;
}


.cm-header {
  background: #f7f7f7;
  padding: 4px 0;
  border: solid #ddd;
  border-width: 1px 1px 0 1px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 1rem;
  &:before {
    content: '編集';
    font-weight: normal;
    font-size: 12px;
    display: block;
    padding: 7px 10px;
    background: #eee;
    line-height: 1rem;
  }
}
.cm-toggle-btn {
  font-weight: normal;
  font-size: 11px;
  border: solid 1px #ddd;
  border-radius: 2px;
  background-color: #fff;
  padding: 2px 4px;
  margin: 0 2px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
}

.codemirror-editor > .CodeMirror.cm-s-default {
  border: solid 1px #ddd;
}

.CodeMirror-merge.CodeMirror-merge-2pane {
  overflow: hidden;
}

.CodeMirror-merge-pane-rightmost:after {
  content: '公開中';
  position: absolute;
  top: -32px;
  left: 0;
  z-index: 22;
  padding: 7px 10px;
  font-size: 12px;
  background: #eee;
  line-height: 1rem;
}

.colorEditor textarea{
  font-size: 13px;
  line-height: 1.5em;
}

.actions {
  width: 100%;
  padding: 1rem;
  background-color: #f7f7f7;
  display: flex;
  justify-content: end;
  z-index: 100;
}

</style>