const google = window.google;

export default class OverlayMarker extends google.maps.OverlayView {
  // 地図画面で使用しているクラスターマーカーのオーバーレイを一部利用
  constructor(map, position, logo, options = {}) {
    super();
    this.position = position;
    this.logo = logo;
    this.id = null
    this.div = null
    this.options = options
    this.draggable = options['draggable'] ? true : false
    this.dragEndCallback = options['callback'] ? options['callback'] : null
    this.addClassStack = options['className'] ? options['className'] : null
    this.setMap(map)
    this.map = map;
    this.origin = null
  }

  // 必須メソッド
  onAdd() {
    this.div = document.createElement('div');
    this.div.style.position = 'absolute'
    const icon = document.createElement('img')
    icon.className = "icon";
    icon.src = this.logo
    this.div.appendChild(icon);

    //class設定
    this.addClass("marker");
    if (this.addClassStack != null) {
      this.addClass(this.addClassStack);
      this.addClassStack = null;
    }

    const panes = this.getPanes();
    panes.mapPane.appendChild(this.div);
    panes.overlayLayer.appendChild(this.div);
    panes.overlayMouseTarget.appendChild(this.div);

    if(this.draggable) {
      const targetDiv = this.map.getDiv()
      const move = (e) => {
        const origin = this.origin;
        const left = origin.clientX - e.clientX;
        const top = origin.clientY - e.clientY;
        const pos = this.getProjection().fromLatLngToDivPixel(this.position);
        const latLng = this.getProjection().fromDivPixelToLatLng(new google.maps.Point(pos.x - left, pos.y - top));
        this.origin = e;
        this.position = latLng;
        this.draw();
      }

      this.div.addEventListener('mousedown', (e) => {
        this.origin = e
        this.map.set('gestureHandling', "none");
        targetDiv.addEventListener('mousemove', move)
      })

      this.div.addEventListener('mouseup', (e) => {
        this.map.set('gestureHandling', "auto");
        targetDiv.removeEventListener("mousemove", move)
        if(this.dragEndCallback) {
          this.dragEndCallback(this.position);
        }
      })
    }    
  }

  // 必須メソッド マーカー描画
  draw() {
    let position = this.position;
    const w = this.div.offsetWidth;
    const h = this.div.offsetHeight;
    const point = this.getProjection().fromLatLngToDivPixel(position);
    point.x -= w / 2;
    point.y -= h / 2;
    this.div.style.left = point.x + 'px';
    this.div.style.top = point.y + 'px';

  }

  // 親クラスのクラスター数で再描画
  update() {
    if (this.div === null) {
      console.info("null", this.parent.points[0])
      return
    }
  }

  // 必須メソッド
  onRemove() {
    if(!this.div) return;
    this.div.parentNode.removeChild(this.div);
    this.div = null;
  }

  // マーカー削除
  remove() {
    this.setMap(null)
  }

  addClass(className) {
    if (!this.div) {
      this.addClassStack = className
      return
    }
    this.div.classList.add(className)
  }

  removeClass(className) {
    if (!this.div) {return}
    this.div.classList.remove(className)
  }

  setPosition(latLng) {
    this.position = latLng
  }
}
