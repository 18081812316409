<template>
  <div class="range-container">
    <label class="date-edit" for="start">
      <input class="date-picker" v-model="_range.start" type="date" id="start" name="start" ref="start" @change="onDateChanged('start')" :disabled="disabled">
    </label>
    <div class="wave-dash">〜</div>
    <label class="date-edit">
      <input class="date-picker" v-model="_range.end" type="date" name="end" ref="end" @change="onDateChanged('end')" :disabled="disabled">
    </label>
  </div>
</template>

<script>
export default {
  props: ['range', 'disabled'],
  data() {
    return {
      _range: {...this.range},
    }
  },
  computed: {
    dateError() {
      if(new Date(this._range.end).getDate() - new Date(this._range.start).getDate() < 2 || !this._range.start || !this._range.end) return this.$emit('dateError', '期間を1日以上選択してください');
      return this.$emit('dateError', '');;
    }
  },
  watch: {
    range: {
      handler(newRange) {
        this._range = newRange;
      },
      deep: true
    }
  },
  created() {
    if(!this._range.start || !this._range.end) {
      this.initialDate();
    }
    this.updateDateObj()
  },
  methods: {
    onDateChanged(ref) {
      this.$refs[ref].blur()
      const currentTime = new Date().getTime();
      const dateStartTime = new Date(this._range.start).getTime();
      if(dateStartTime > currentTime) {
        this._range.start = '';
      }
      if(new Date(this._range.end).getTime() > new Date().getTime() || new Date(this._range.start).getTime() > new Date(this._range.end).getTime()) {
        this._range.end = '';
      }
      this.updateDateObj();
    },
    updateDateObj() {
      this.$emit('onRangeChanged', this._range);
    },
    parseQueryString() {
      const pairs = location.search.slice(1).split('&');
      for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        const key = decodeURIComponent(pair[0]);
        const value = decodeURIComponent(pair[1] || '');
        if(!this.isValidDateFormat(value)) return;

        if (key) {
          this._range[key] = value;
        }
      }
      this.onDateChanged();

    },
    isValidDateFormat(dateStr) {
      // 正規表現パターン: YYYY-MM-DD
      const pattern = /^\d{4}-\d{2}-\d{2}$/;

      // 正規表現でフォーマットをチェック
      if (!pattern.test(dateStr)) {
        return false;
      }

      // 日付オブジェクトを作成して、実際の日付として有効かどうかをチェック
      const date = new Date(dateStr);
      const dateComponents = dateStr.split('-').map(Number);

      return (
        dateComponents[0] === date.getFullYear() &&
        dateComponents[1] === date.getMonth() + 1 &&
        dateComponents[2] === date.getDate()
      );
    },
    initialDate() {
      const today = new Date();
      const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
      const endYear = today.getFullYear();
      const endMonth = ("0" + (today.getMonth() + 1)).slice(-2);
      const endDay = ("0" + today.getDate()).slice(-2);
      const startYear = lastWeek.getFullYear();
      const startMonth = ("0" + (lastWeek.getMonth() + 1)).slice(-2);
      const startDay = ("0" + lastWeek.getDate()).slice(-2);

      this._range.start = startYear + "-" + startMonth + "-" + startDay;
      this._range.end = endYear + "-" + endMonth + "-" + endDay;
    }
  }
}
</script>

<style lang="scss" scoped>
.range-container {
  display: flex;
  align-items: center;

  .date-edit {
    position: relative;

    .date-picker {
      border: solid 1px rgba(0,0,0,0.12);
      border-radius: 3px;
      padding: 4px 12px;
      letter-spacing: 0.1px;
    }

    input[type=date]{
      position: relative;
      width: 120px;
      color: rgba(0, 0, 0, 0.6);
    }
    input[type=date]::-webkit-calendar-picker-indicator {
        position: absolute;
        right: 6px;
        padding-left: 100%;
    }
  
    .date-picker:focus-visible {
      border: solid 1px rgb(127,188,57);
      outline: solid 1px rgb(127,188,57);
    }

  }
  .wave-dash {
    margin: 0 8px;
  }
}
</style>