<template>
  <div id="firebaseui-auth-container">
    <SignIn
      v-show="isDefault"
      :forcedMultiFactor="forcedMultiFactor"
      :recaptchaVerifier="recaptchaVerifier"
      :hasRecaptchaToken="hasRecaptchaToken"
      @authorized="authorized"
      @sendEmailVerification="sendEmailVerification"
      @mfaRequired="mfaRequired"
      @resetPassword="loginState = 'RESET_PASSWORD'"
      @error="error">
      <template #recaptcha>
        <Recaptcha ref="recaptcha"/>
      </template>
    </SignIn>

    <EmailVerification
      v-if="isEmailVerification"
      :user="user"
      @emailVerified="emailVerified"></EmailVerification>

    <MultiFactor
      v-if="isMfa"
      :user="user"
      :resolver="resolver"
      :recaptchaVerifier="recaptchaVerifier"
      @authorized="multiFactorAuthorized"
      @cancel="resetData"></MultiFactor>

    <ResetPassword
      v-if="isResetPassword"
      :prevState="resetData"></ResetPassword>

    <Alert></Alert>

    <v-form ref="token" method="post">
      <input type="hidden" name="csrfmiddlewaretoken" :value="$attrs.csrfmiddlewaretoken" />
      <input type="hidden" name="id_token" id="id_token" />
    </v-form>
  </div>
</template>

<script>
import SignIn from '@/components/firebase/SignIn'
import EmailVerification from '@/components/firebase/EmailVerification'
import MultiFactor from '@/components/firebase/MultiFactor'
import ResetPassword from '@/components/firebase/ResetPassword'
import Recaptcha from "@/components/firebase/Recaptcha"
import Alert from '@/components/Alert'
import { showErrorMessageByCode } from '@/utils/firebase'

import {
  getAuth,
  signOut,
  getMultiFactorResolver,
  getRedirectResult,
} from "firebase/auth";

const LOGIN_STATES = {
  DEFAULT: 'DEFAULT',                         // メール＆パスワード入力
  EMAIL_VERIFICATION: 'EMAIL_VERIFICATION',   // メールアドレスの認証
  MFA: 'MFA',       // MFA
  RESET_PASSWORD: 'RESET_PASSWORD',           // パスワード再設定
}

const FORCED_MULTI_FACTOR = false  // ２段階認証を強制するモード TODO: 移動

export default {
  mixins: [],
  components: {
    SignIn,
    EmailVerification,
    MultiFactor,
    ResetPassword,
    Alert,
    Recaptcha,
  },
  data() {
    return {
      auth: getAuth(),
      user: null,               // Firebaseから返されるuser
      loginState: LOGIN_STATES.DEFAULT, // ログイン画面の状態
      forcedMultiFactor: FORCED_MULTI_FACTOR,

      // Firebase用のインスタンスやクラス
      resolver: null,
      recaptchaVerifier: null,
      hasRecaptchaToken: false,
    }
  },
  computed: {
    isDefault() { return this.loginState === LOGIN_STATES.DEFAULT },
    isEmailVerification() { return this.loginState === LOGIN_STATES.EMAIL_VERIFICATION },
    isMfa() { return this.loginState === LOGIN_STATES.MFA },
    isResetPassword() { return this.loginState === LOGIN_STATES.RESET_PASSWORD },
  },
  async mounted() {
    await this.initRecaptcha()
    await getRedirectResult(this.auth)
      .then(result => {
        if (!result) return

        this.user = result.user
        this.serverAuthorize()
      })

    // Django でログイン中はここに来れないはず。Firebase 側でのみ認証済みが残っている場合を考慮してログアウト処理
    await signOut(this.auth)
    // onAuthStateChanged(this.auth, async (user) => {})
  },
  methods: {
    authorized(user) {
      // メールパス認証をパスした場合（但し、2段階認証が有効でないこと）
      this.user = user
      this.serverAuthorize()
    },
    sendEmailVerification(user) {
      // メール確認が必要な場合
      this.user = user
      this.loginState = LOGIN_STATES.EMAIL_VERIFICATION
    },
    mfaRequired(error) {
      // 2段階認証が有効な場合
      this.resolver = getMultiFactorResolver(this.auth, error)
      this.loginState = LOGIN_STATES.MFA
    },
    error(error) {
      showErrorMessageByCode(error.code)
    },

    emailVerified() {
      // メール確認が取れた場合
      this.loginState = LOGIN_STATES.MFA
    },

    multiFactorAuthorized(user) {
      // 2段階認証をパスした時
      // this.loginState = LOGIN_STATES.DEFAULT
      this.user = user
      this.serverAuthorize()
    },

    async resetData() {
      this.loginState = LOGIN_STATES.DEFAULT
      this.initRecaptcha()
      // recaptchaの状態を再取得して設定
      const recaptchaToken = await this.recaptchaVerifier.verify()
      this.hasRecaptchaToken = !!recaptchaToken
    },

    async serverAuthorize() {
      // django 側のログイン処理
      const formData = this.$refs.token.$el
      const idToken = await this.user.getIdToken()
      formData.id_token.value = idToken
      formData.submit()
    },

    async initRecaptcha() {
      this.$nextTick(async()=> {
        this.recaptchaVerifier = await this.$refs.recaptcha.initRecaptcha(token=> {
          this.hasRecaptchaToken = !!token
        })
      })
    },
  },
}

</script>

<style lang="scss">
</style>
