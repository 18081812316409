const hasOwnProperty = Object.prototype.hasOwnProperty

export function _has(obj, key) {
  return hasOwnProperty.call(obj, key)
}

export function _debounce(func, timeout = 300){
  let timer;
  return function(...args) {
    clearTimeout(timer)
    timer = setTimeout(() => { func.apply(this, args) }, timeout)
  }
}