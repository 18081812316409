import { createApp } from "vue";
import vuetify from '@/plugins/vuetify'
import ConsoleConfirm from '@/components/console/ConsoleConfirm'

export default {
  methods: {
    confirm(title, message, color, okLable, cancelLabel) {
      return new Promise((resolve) => {
        const option = {
          message: message,
          title: title,
          color: color,
          okLable: okLable,
          cancelLabel: cancelLabel,
          ok: () => {
            return resolve(true);
          },
          cancel: () => {
            return resolve(false);
          }
        }
        // マウントさせるためのelementを作成する、
        // このelementはコンポーネントを閉じた際にConsoleConfirmから破棄される
        const el = document.createElement('div')
        document.getElementsByTagName('body')[0].appendChild(el)
        createApp(ConsoleConfirm, option).use(vuetify).mount(el)
      })
    }
  }
}