<template>
  <section class="point-view">
    <div v-if="processing">
      <v-alert text type="warning">現在インポートデータの処理中です、しばらくお待ちください</v-alert>
    </div>
    <div v-else>
      <Console
        modalType="wide"
        :service="restService"
        :itemName="itemName"
        :fields="fields"
        :searchQuery="text"
        :customQuery="customQuery"
        :tableActions="tableActions"
        :limit="limit"
        :rowClickAction="rowClickAction"
        :rowClassConditions="rowClassConditions"
        ref="console"
      >
        <template #control>
          <div  class="mb-2">
            <!-- <v-btn class="mr-2" depressed color="primary" @click.stop="openImportModal">インポート</v-btn> -->
            <v-btn depressed color="primary" @click.stop="openExportModal">エクスポート</v-btn>
          </div>
        </template>
        <template #search="{submit}">
          <v-row align="center" justify="start">
            <v-col class="search-bar-frame">
            <ConsoleSearch
                v-model="text"
                @submit="submit"
                :searchByButton="false"
              />
            </v-col>
            <v-col cols="wider">
              <div class="d-flex align-center">
                <div class="d-flex">
                  <v-checkbox
                    class="mr-4 d-flex align-center"
                    color="primary"
                    @change="onChangeAddress(submit)"
                    label="住所変更"
                    v-model="changeAddress"
                    name="changeAddress"
                  ></v-checkbox>
                </div>
                <div>
                  <v-checkbox
                  class="d-flex align-center"
                  color="primary"
                  @change="onChangeMaintenance(submit)"
                  label="緯度経度の調整が必要"
                  v-model="maintenance"
                  name="maintenance"
                ></v-checkbox>
                </div>
              </div>
            </v-col>
            <v-col cols="auto">
              <div>
                {{restService.total}} 件
              </div>
            </v-col>

          </v-row>
        </template>
        <template #form="{formData, formHandler, errorMessage}">
          <PointForm
            :formData="formData"
            :formHandler="formHandler"
            :service="restService"
            :errorMessage="errorMessage"
            ref="pointForm"
          />
        </template>
      </Console>

      <ConsoleModal ref="importModal" v-model="isImportDialogOpen" :title="importModalTitle" :wideModal="true">
        <v-card-text>
          <LoadingOverlay :isActive="isParsing" message="インポートファイルの処理中" />
          <div v-if="!hasImportData" class="mt-4">
            <v-row>
              <v-col cols="2">
                文字コード
              </v-col>
              <v-col>
                <v-radio-group class="mt-0" v-model="csvFormat.encoding">
                  <v-radio label="UTF-8（エクセルで編集）" value="utf-8-sig"></v-radio>
                  <v-radio label="UTF-8" value="utf-8"></v-radio>                  
                  <v-radio label="Shift-JIS" value="cp932"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                区切り文字
              </v-col>
              <v-col>
                <v-radio-group class="mt-0" v-model="csvFormat.delimiter" :column="false">
                  <v-radio label="カンマ" value="," class="mr-8"></v-radio>
                  <v-radio label="タブ" value="tab"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                測地形
              </v-col>
              <v-col>
                <v-radio-group class="mt-0" v-model="csvFormat.geodetic" :column="false">
                  <v-radio label="世界測地形" value="wgs" class="mr-8"></v-radio>
                  <v-radio label="日本測地形" value="tokyo-datum"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row class="align-center">
              <v-col cols="2">
                更新方法
              </v-col>
              <v-col>
                <v-radio-group v-model="csvFormat.importMode">
                  <v-radio value="update" label="既存のデータに追加する（キーが同じデータは上書きします）"></v-radio>
                  <v-radio value="replace" label="データをすべて差し替える（既存のデータはすべて削除します）"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center">
                <v-btn text class="mb-4" color="primary" @click="sampleExport">サンプルデータ ダウンロード</v-btn>
                <p>（UTF-8（エクセルで編集）／カンマ区切り）</p>
              </v-col>
            </v-row>
          </div>

          <div v-if="!!importErrors.length">
            <v-alert text type="error" class="mt-4">
              <div class="mb-4">
                <span class="font-weight-bold">インポートに失敗しました</span>
                <span v-if="importDate">
                  （{{importDate}}）
                </span>
              </div>
              <div class="modal-error-message" v-for="(err,i) in importErrors" :key="`import_err_${i}`">{{err}}</div>
            </v-alert>
          </div>

          <div v-if="!!importWarnings.length">
            <v-alert text type="warning" class="mt-4">
              <div class="modal-warning-message" v-for="(warn,i) in importWarnings" :key="`import_warn_${i}`">{{warn}}</div>
            </v-alert>
          </div>
          <div v-if="hasImportData">
            <v-alert text type="info" class="mt-4">
              <p class="mb-2" v-if="importCount">全{{importCount.toLocaleString()}}件（{{previewLimit}}件まで表示）</p>
              <p class="mb-0" v-if="importGeocodeCount">
                ジオコーディングを行う件数: <span class="font-weight-bold">{{importGeocodeCount.toLocaleString()}}件</span>
                <span v-if="importGeocodeCount > geocodeWarningCount" class="error--text ml-4">
                  注意: 件数が{{geocodeWarningCount.toLocaleString()}}件を超えています
                </span>
              </p>
            </v-alert>

            <v-simple-table class="point-peview">
              <thead><tr>
                <th class="text-left" v-for="field in importPreviewFields" :key="field">{{field}}</th>
              </tr></thead>
              <tbody>
                <tr v-for="(data, idx) in importPreviewData" :key="data.key">
                  <td v-for="key in importPreviewFields" :key="data.key + key + idx">
                    {{data[key]}}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </v-card-text>
        <v-divider class="mt-0"></v-divider>

        <v-card-actions>
          <div class="inport-controls d-flex align-center justify-space-between">
            <div class="flex-grow-1">
              <span class="error--text" v-if="errorMessage">{{errorMessage}}</span>
            </div>
            <div>
              <v-btn class="ml-2" depressed @click="closeImportModal">閉じる</v-btn>
              <v-btn class="ml-2" depressed v-if="hasImportData" @click="onCancelImport">インポート取り消し</v-btn>
              <v-file-input v-if="!hasImportData" class="upload-btn"
                accept=".csv"
                prepend-icon="CSVファイルを選択"
                hide-input v-model="inputCsvFile"
                @change="onChangeCsv">
              </v-file-input>
              <v-btn v-else class="ml-2" depressed :disabled="!importCount" color="primary" @click="onStartImport">インポート</v-btn>
            </div>
          </div>
        </v-card-actions>

        <LoadingOverlay :isActive="restService.isLoading" />
      </ConsoleModal>

      <ConsoleModal ref="exporttModal" v-model="isExportDialogOpen" title="エクスポート">
        <v-card-text>
          <LoadingOverlay :isActive="isParsing" message="エクスポート処理中" />
          <v-row class="mt-2">
            <v-col>現在の絞り込み条件でデータをエクスポートします。</v-col>
          </v-row>
          
          <!--
          <v-row class="mt-4">
            <v-col cols="2">
              文字コード
            </v-col>
            <v-col>
              <v-radio-group class="mt-0" v-model="csvFormat.encoding" disabled>
                <v-radio label="UTF-8（エクセルで編集）" value="utf-8-sig"></v-radio>
                <v-radio label="UTF-8" value="utf-8"></v-radio>                
                <v-radio label="Shift-JIS" value="cp932"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              区切り文字
            </v-col>
            <v-col>
              <v-radio-group class="mt-0" v-model="csvFormat.delimiter" :column="false" disabled>
                <v-radio label="カンマ" value="," class="mr-8"></v-radio>
                <v-radio label="タブ" value="tab"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          -->

        </v-card-text>
        <v-divider class="mt-0"></v-divider>
        <v-card-actions>
          <div class="inport-controls d-flex align-center justify-space-between">
            <div class="flex-grow-1">
              <span class="error--text" v-if="errorMessage">{{errorMessage}}</span>
            </div>
            <div>
              <v-btn class="ml-2" variant="tonal" @click="closeExportModal">キャンセル</v-btn>
              <v-btn class="ml-2" variant="flat" color="primary" @click="onClickExport">エクスポート</v-btn>
            </div>
          </div>
        </v-card-actions>
      </ConsoleModal>

      <v-snackbar v-model="showNotify" text :color="notifyType">
        <v-icon v-if="notifyType == 'success'" color="success" class="mr-4">check_circle</v-icon>
        <v-icon v-else-if="notifyType == 'warning'" color="warning" class="mr-4">warning</v-icon>
        <v-icon v-else-if="notifyType == 'error'" color="error" class="mr-4">error</v-icon>
        <span v-html="notifyMessage"></span>
      </v-snackbar>
    </div>
  </section>
</template>

<script>
import JSZip from 'jszip'

import PointService from '@/services/pointservice';
import MessageMixin from '@/mixins/message'
import ConfirmMixin from '@/mixins/confirm'

import Console from '@/components/console/Console';
import ConsoleModal from '@/components/console/ConsoleModal';
import ConsoleSearch from '@/components/console/ConsoleSearch';
import LoadingOverlay from '@/components/LoadingOverlay';
import PointForm from './forms/PointForm';
import {format, parse} from 'date-fns';
import {_has} from '@/utils/common';

// インポートプレビューの並び順
/*const IMPORT_SORT_LIST = [
  'ポイントID','店名','住所','緯度','経度','ジャンル','屋号ID','屋号名称','非表示フラグ','キャンペーン不参加フラグ'
]*/
export default {
  components: {
    Console,
    PointForm,
    ConsoleSearch,
    ConsoleModal,
    LoadingOverlay,
  },
  mixins: [MessageMixin, ConfirmMixin],
  data() {
    return {
      isImportDialogOpen: false,
      isExportDialogOpen: false,
      showNotify: false,
      notifyType: "succsess",
      notifyMessage: "",

      errorMessage: null,  //フォームに送るエラーメッセージ

      text: '',
      maintenance: false,
      changeAddress: false,

      restService: new PointService(`/api/maps/${storelocator.map.id}/points/`),
      itemName: '店舗',
      fields: [
        { id: 1, label: '個店CD', key: 'key', type: 'string', sortable: true},
        { id: 2, label: '個店名', key: 'name', type: 'string', sortable: true},
        { id: 3, label: '住所', key: 'address', type: 'string', sortable: true},
        { id: 4, label: '取込日', key: 'created_at', type: 'datetime', sortable: true},
      ],
      rowClassConditions: [{
        condition: r => {return true},
        className: "cursor-pointer"
      }],      
      tableActions: [
        // {label: '詳細', action: 'edit'},
      ],
      rowClickAction: {action: "edit"},
      limit: 50,

      previewLimit: 100,
      importLoading: false,
      importDataId: null,
      importErrors: [],
      importWarnings: [],
      importCount: 0,
      importGeocodeCount: 0,
      importDate: '',
      importPreviewData: [],
      importPreviewFields: [],
      inputCsvFile: undefined,
      csvFormat: {
        importMode: "update",
        geodetic: "wgs",
        encoding: "utf-8-sig",
        delimiter: ','
      },
      polling: null,

      importFileSliceSize: 20 * 1024 * 1024,
      importFileSliceThreshold: 50 * 1024 * 1024,

      geocodeWarningCount: 5000, // ジオコード件数の警告を表示する数値
    }
  },
  mounted() {
    this.$refs.importModal.$el.addEventListener('hidden', this.onCloseModal);
  },
  computed: {
    customQuery() {
      return {
        maintenance: this.maintenance,
        changeAddress: this.changeAddress,
        text: this.text
      }
    },
    remainingCount() {
      if(this.importCount <= this.importPreviewData.length) {
        return 0
      }
      return this.importCount - this.importPreviewData.length
    },
    isParsing() {
      return this.importStatus === 0
    },
    processing() {
      return this.restService.error === 'Other import process is running'
    },
    hasImportData() {
      return this.importPreviewData.length
    },
    importModalTitle() {
      if(this.hasImportData) {
        return 'インポートデータの確認'
      }
      return '店舗をインポート'
    }
  },
  watch: {
    processing() {
      if(this.processing && !this.polling) {
        this.setPolling()
      } else if(!this.processing){
        clearInterval(this.polling)
      }
    }
  },
  methods: {
    setPolling() {
      this.polling = setInterval(()=>{
        this.restService.list()
      }, 60000)
    },

    onChangeMaintenance(submit) {
      const query = { maintenance: this.maintenance };
      submit(query);
    },

    onChangeAddress(submit) {
      const query = { changeAddress: this.changeAddress };
      submit(query);
    },

    async openImportModal() {
      this.isImportDialogOpen = true
      const response = await this.restService.getImportData()

      if (this.restService.error) {
        this.errorMessage = "エラーが発生したため処理を中断しました"
        return
      }

      this.setPreviewData(response)
      if(response.errors?.warnings) {
        this.setImportWarnings(response.errors.warnings);
      }
      if(response.errors?.errors) {
        this.setImportWarnings(response.errors.errors);
      }
    },

    setPreviewData(data) {
      this.errorMessage = null
      if(data.id) {
        this.importDataId = data.id
        this.importCount = data.count
        this.importGeocodeCount = data.geocode_count
        if (data.params) {
          this.csvFormat.encoding = data.params.encoding
          this.csvFormat.geodetic = data.params.geodetic
          this.csvFormat.mode = data.params.mode
        }
        if(data.created_at) {
          this.importDate = format(parse(data.created_at, 'yyyy-MM-dd HH:mm:ss', new Date()), 'yyyy/MM/dd HH:mm:ss');
        } else {
          this.importDate = '';
        }
        if(data.items?.length) {
          this.importPreviewData = data.items;
          // const fields = Object.keys(data.items[0]).sort((a,b) => {
          //   return IMPORT_SORT_LIST.indexOf(a) - IMPORT_SORT_LIST.indexOf(b)
          // });
          this.importPreviewFields = Object.keys(data.items[0]);
        }
      }
    },

    async onChangeCsv(e) {
      this.errorMessage = null
      const file = this.inputCsvFile
      if(file) {
        let zipFiles = [];
        let response = {};
        let index = 0;
        this.clearImportData();

        if(JSZip.support.blob) {
          if(file.size > this.importFileSliceThreshold) {
            const slicedFiles = this.sliceFiles(file, this.importFileSliceSize);
            const zipPromises = slicedFiles.map(async(f, i)=> {
              const zipped = this.createZip(f, `import_${i}.csv`)
              return zipped
            })
            zipFiles = await Promise.all(zipPromises)
          } else {
            zipFiles[0] = await this.createZip(file, `import.csv`)
          }
        }
        if(zipFiles?.length > 1) {
          for await (const zipFile of zipFiles) {
            const formData = this.createFormData()
            formData.append('file', zipFile);
            formData.append('file_index', index);
            formData.append('files_length', zipFiles.length);

            // TODO ここエラーハンドリングしていない
            const _response = await this.restService.importCsv(formData);
            if(_response.status == 200) {
              response = _response
            }
            index += 1
          }
        } else {
          const formData = this.createFormData()
          formData.append('file', zipFiles[0]);
          response = await this.restService.importCsv(formData)
        }

        if (response.status != 200) {
          if (!response.data) {
            this.errorMessage = "エラーが発生したため処理を中断しました"
            return
          }
          this.setImportErrors(response.data)
          return
        }

        if(response) {
          if(response.data.errors) {
            const {errors, format_errors, warnings} = response.data.errors
            if(errors || format_errors) {
              this.setImportErrors(response.data.errors)
            }
            if(warnings) {
              this.setImportWarnings(warnings)
            }
          }
          this.setPreviewData(response.data)
        };
      }
    },

    sliceFiles(file, sliceSize) {
      // sliceSize毎にfileを分割
      const size = file.size;
      const count = Math.ceil(size / sliceSize);
      const sliced_files = Array(count).fill(0).map((_,i)=>{
        return file.slice(i * sliceSize, (i + 1) * sliceSize)
      })
      return sliced_files
    },

    async createZip(file, fileName) {
      const zip = new JSZip()
      zip.file(fileName, file)
      const zipFile = await zip.generateAsync({
        type: "blob",
        compression: "DEFLATE",
        compressionOptions: {
          level: 9
        }
      });
      return zipFile
    },

    createFormData() {
      const params = new FormData();
      params.append('encoding', this.csvFormat.encoding);
      params.append('geodetic', this.csvFormat.geodetic);
      params.append('mode', this.csvFormat.importMode);
      params.append('delimiter', this.csvFormat.delimiter);
      return params
    },
    setImportErrors(data) {
      const {errors=[], format_errors={}} = data
      if(_has(format_errors, 'required')) {
        format_errors.required.forEach((e)=> {
          this.importErrors.push(`必要な列「${e}」が見つかりませんでした。`);
        });
      }
      if(_has(format_errors, 'duplicated')) {
        format_errors.duplicated.forEach((e)=> {
          this.importErrors.push(`重複した列「${e}」が見つかりました。`);
        });
      }
      if(_has(format_errors, 'encode')) {
        this.importErrors.push(`文字コードがUTF-8ではありません。推測された文字コード: ${format_errors.encode}`);
      }
      if(errors.length) {
        errors.forEach(err => {
          if(err[2] == "required") {
            this.importErrors.push(`${err[0]}行目に必須項目「${err[1]}」が見つかりませんでした。`);
          }
        });
      }
    },
    setImportWarnings(warnings=[]) {
      if(warnings.length) {
        warnings.forEach((warn)=> {
          if(warn[2] == 'duplicated')
          this.importWarnings.push(`${warn[0]}行目に重複の${warn[1]}「${warn[3]}」が見つかりました。重複行はスキップされます`);
        });
      }
    },
    async onCancelImport() {
      this.errorMessage = null
      if (this.importDataId) {
        const response = await this.restService.importCancel({id: this.importDataId})
        if (response.status != 200) {
          this.errorMessage = "エラーが発生したため処理を中断しました"
          return
        }
        this.clearImportData()
      }
    },
    async onStartImport() {
      if(this.importDataId) {
        this.errorMessage = null
        if(await this.confirm('店舗のインポート', 'インポートを実行します。本当によろしいですか？')) {
          const params = {
            // ...this.csvFormat,
            id: this.importDataId,
          }
          const response = await this.restService.importStart(params)
          if (response.status != 200) {
            this.errorMessage = "エラーが発生したため処理を中断しました"
            return
          }

          // 変更されたextra_fieldsの値をアップデート
          storelocator.map.extra_fields = response.data.extra_fields

          this.closeImportModal();
          this.showNotification('インポートが完了しました。')
          this.restService.list({
            page: 1,
            limit: this.limit,
          })
        }
      }
    },
    showNotification(message, status='success') {
      this.notifyType = status
      this.notifyMessage = message
      this.showNotify = true
    },
    onCloseModal() {
      this.importLoading = false;
      this.clearImportData();
    },
    closeImportModal() {
      this.isImportDialogOpen = false
      this.onCloseModal()
    },
    clearImportData() {
      this.inputCsvFile = undefined
      this.importDataId = null;
      this.importErrors = [];
      this.importWarnings = [];
      this.importCount = 0;
      this.importGeocodeCount = 0;
      this.importPreviewFields = [];
      this.importPreviewData = [];
    },
    openExportModal() {
      this.isExportDialogOpen = true
    },
    closeExportModal() {
      this.isExportDialogOpen = false
    },
    onClickExport() {
      const params = Object.keys(this.csvFormat).map(f => `${f}=${this.csvFormat[f]}`).join('&')      
      const query = Object.keys(this.$refs.console.query).map(f => `${f}=${this.$refs.console.query[f]}`).join('&')

      console.debug("request query", this.$refs.console.query)
      console.debug("request url", `${this.restService.API_URL}export?${params}&${query}`)
      window.open(`${this.restService.API_URL}export?${params}&${query}`)
      this.isExportDialogOpen = false
    },
    sampleExport() {
      window.open(`${this.restService.API_URL}sample?encoding=utf-8&delimiter=,`)
      this.isExportDialogOpen = false
    }
  }
}
</script>

<style lang="scss">
.v-dialog .v-overlay__content > .v-card > .v-card-text {
  color: rgba(0,0,0,.6);
  font-size: .875rem;
  padding: 0 24px 20px;
}
.inport-controls {
  width: 100%;
}

.search-bar-frame {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 245px
}

/*
.modal-error-message {
  margin-bottom: 10px;
  font-size: .9em;
}
*/

.point-view, .point-peview {
  table tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .02);
  }
}

.point-peview {
  tr, td {
    white-space: nowrap
  }
}

// ファイルアップロードフォームのデザイン上書き
.upload-btn {
  display: inline-block;
  margin: 0;
  padding: 0 16px;
  height: 36px;
  border-radius: 4px;
  background-color: var(--v-primary-base);
  vertical-align: middle;

  .v-input__prepend-outer {
    margin:0;
    padding:0;
    .v-input__icon {
      width: auto;
      margin-top: 3px;
      button {
        font-size: .875rem;
        color: white;
        &::after {
          opacity:0
        }
      }
    }
  }
  transition: .4s;
  &:hover {
    opacity: .9;
  }
}
</style>