import RestService from './restservice'

export default class DesignService extends RestService {
  constructor(url) {
    super(url);
  }



  async uploadImage(params) {
    this.isLoading = true;
    return this.httpclient.postFile('/api/images/', params)
      .finally(()=> {
        this.isLoading = false;
      })
  }

  async getConfig() {
    return this.httpclientWrapper(this.httpclient.get(`${this.API_URL}config`))
      .finally(()=> {
        this.isLoading = false;
      })
  }

  async setConfig(params) {
    return this.httpclientWrapper(this.httpclient.put(`${this.API_URL}config`, params))
      .finally(()=> {
        this.isLoading = false;
      })
  }

  async applyConfig(params) {
    return this.httpclientWrapper(this.httpclient.put(`${this.API_URL}config/apply/`, params))
      .finally(()=> {
        this.isLoading = false;
      })
  }
}