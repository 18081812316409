<template>
  <div>
    <div v-if="!succeeded">
      <v-form ref="passwordForm">
        <p class="mb-4">新しいパスワードを設定します</p>
        <v-text-field
          v-model="newPassword"
          id="new-password"
          autocomplete="new-password"
          density="compact"
          type="password"
          label="新しいパスワード"
          :rules="[rules.required, rules.min6, rules.invalidPasswordCharactor]"
          color="primary"
        ></v-text-field>
        <v-text-field
          v-model="newPasswordConfirm"
          id="new-password-confirm"
          autocomplete="new-password"
          density="compact"
          type="password"
          label="新しいパスワード（再入力）"
          :rules="[rules.required, rules.min6, rules.confirmPassword]"
          color="primary"
        ></v-text-field>

        <v-btn
          block
          color="primary"
          depressed
          size="large"
          @click="onChangePassword"
          :disabled="isLoading"
        >
          パスワード再設定
        </v-btn>
      </v-form>
    </div>
    <div v-else-if="succeeded">
      <p class="mb-4">
        パスワードを再設定しました。
      </p>
      <v-btn text color="primary" href="/account/login">
        ログイン
      </v-btn>
    </div>
  </div>
</template>

<script>

import {
  getAuth,
  confirmPasswordReset
} from "firebase/auth";
import { showErrorMessageByCode } from '@/utils/firebase'
import rules from "@/utils/rules"

export default {
  mounted() {
  },
  props: {
    oobCode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      auth: getAuth(),
      newPassword: '',
      newPasswordConfirm: '',
      succeeded: false,
      isLoading: false,
      rules: {
        ...rules,
        confirmPassword: v => v === this.newPassword || 'パスワードが一致しません',
      },
    }
  },
  computed: {
  },
  methods: {
    onChangePassword() {
      if(this.$refs.passwordForm.validate()) {
        this.isLoading = true
        confirmPasswordReset(this.auth, this.oobCode, this.newPassword)
          .then(()=> {
            this.succeeded = true
          })
          .catch(e=> {
            showErrorMessageByCode(e.code)
          })
          .finally(()=>{
            this.isLoading = false
          })
      }
    },
  }
}
</script>
