<template>
  <div class="firebase-multifactor">
    <v-container v-if="isDefault">
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col cols="6" class="pt-8 pb-8">
          <v-progress-linear
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>

    <SelectNumber
      v-if="isSelectNumber"
      :multiFactorHints="multiFactorHints"
      @prevState="resetData"
      @select="selectedNumber"></SelectNumber>

    <MultiFactorRegistration
      ref="registration"
      v-if="isMfaRegistrationStep1"
      :user="user"
      :recaptchaVerifier="recaptchaVerifier"
      @verifyPhoneNumber="verifyPhoneNumber"
      @prevState="resetData"
      @error="error"></MultiFactorRegistration>

    <MultiFactorForm
      v-if="isMfaRegistrationStep2"
      :resolver="resolver"
      :verificationId="verificationId"
      :hintNumber="hintNumber"
      :user="user"
      registerMode
      @authorized="multiFactorAuthorized"
      @prevState="resetData"
      @error="error"></MultiFactorForm>

    <MultiFactorForm
      v-if="isEnterCode"
      :resolver="resolver"
      :verificationId="verificationId"
      :hintNumber="hintNumber"
      @authorized="multiFactorAuthorized"
      @prevState="resetData"
      @error="error"></MultiFactorForm>
  </div>
</template>

<script>
import MultiFactorRegistration from '@/components/firebase/multifactor/MultiFactorRegistration'
import MultiFactorForm from '@/components/firebase/multifactor/MultiFactorForm'
import SelectNumber from '@/components/firebase/multifactor/SelectNumber'

import {
  getAuth,
  PhoneMultiFactorGenerator,
  PhoneAuthProvider
} from "firebase/auth";
import { showErrorMessageByCode } from '@/utils/firebase'

const COMPONENT_STATES = {
  DEFAULT: 'DEFAULT',                         // メール＆パスワード入力
  SELECT_NUMBER: 'SELECT_NUMBER',             // 認証に使用する電話番号選択
  ENTER_CODE: 'ENTER_CODE',                   // 認証コード入力
  MFA_REGISTRATION: 'MFA_REGISTRATION',       // MFA用電話番号登録
  MFA_REGISTRATION_2: 'MFA_REGISTRATION_2',   // MFA用電話番号登録コード入力
}

export default {
  mixins: [],
  components: {
    SelectNumber,
    MultiFactorRegistration,
    MultiFactorForm,
  },
  emits: [
    'authorized',
    'cancel',
  ],
  props: {
    user: {  // 電話番号を登録する場合は必須
      type: Object,
      required: false,
      default: null
    },
    resolver: {  // multiFactorResolver オブジェクト
      type: Object,
      required: false  // 電話番号を登録しない場合は必須
    },
    recaptchaVerifier: {
      type: Object,
      required: true,
    },
    errorCallback: {
      type: Function,
      required: false,
    }
  },
  data() {
    return {
      auth: getAuth(),
      valid: true,              // フォーム入力内容が正常か
      verificationId: '',       // SMS認証時のID
      hintNumber: '',           // 確認コードを送信した電話番号（新規登録以外は末尾のみ）
      isLoading: false,         // 読み込み中フラグ
      componentState: COMPONENT_STATES.DEFAULT, // ログイン画面の状態

      multiFactorHints: [],     // 二段階認証の電話番号一覧（複数登録時のみ使用）

      // Firebase用のインスタンスやクラス
      multiFactorUser: null,
    }
  },
  computed: {
    isDefault() { return this.componentState === COMPONENT_STATES.DEFAULT },
    isSelectNumber() { return this.componentState === COMPONENT_STATES.SELECT_NUMBER },
    isEnterCode() { return this.componentState === COMPONENT_STATES.ENTER_CODE },
    isMfaRegistrationStep1() { return this.componentState === COMPONENT_STATES.MFA_REGISTRATION },
    isMfaRegistrationStep2() { return this.componentState === COMPONENT_STATES.MFA_REGISTRATION_2 },
  },
  async mounted() {
    if (this.user) {
      // 電話番号登録
      this.componentState = COMPONENT_STATES.MFA_REGISTRATION
      return
    }

    const hints = this.resolver.hints.filter(hint => hint.factorId === PhoneMultiFactorGenerator.FACTOR_ID)
    if(hints.length == 1) {
      // 電話番号が1件のみなら自動でSMS送信する
      await this.sendVerifyMessage(hints[0])
      this.componentState = COMPONENT_STATES.ENTER_CODE
      return
    } else if(hints.length > 0) {
      // 複数の電話番号がある場合、電話番号選択肢を表示させる。
      this.multiFactorHints = hints
      this.componentState = COMPONENT_STATES.SELECT_NUMBER
    } else {
      // MFAが有効だが認証方法が無い場合
      this.componentState = COMPONENT_STATES.MFA_REGISTRATION
    }
  },
  methods: {
    selectedNumber(hint) {
      this.sendVerifyMessage(hint)
    },
    async sendVerifyMessage(phoneInfo) {
      // phoneInfo宛に確認コードを送信する
      // reCAPTCHAの承認が必須
      const phoneInfoOptions = {
        multiFactorHint: phoneInfo,
        session: this.resolver.session
      };
      const phoneAuthProvider = new PhoneAuthProvider(this.auth)
      // multiFactorHintの電話番号宛にSMS送信
      this.verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        this.recaptchaVerifier
      )
      this.hintNumber = phoneInfo.phoneNumber.replace('+', '')
      this.componentState = COMPONENT_STATES.ENTER_CODE
    },
    error(error) {
      if(this.errorCallback) {
        this.errorCallback(error)
      } else {
        showErrorMessageByCode(error.code)
      }
    },

    verifyPhoneNumber(phoneNumber, verificationId) {
      // 2段階認証で使う電話番号の確認が取れた時
      this.hintNumber = phoneNumber
      this.verificationId = verificationId
      this.componentState = COMPONENT_STATES.MFA_REGISTRATION_2
    },

    multiFactorAuthorized(user) {
      // 2段階認証をパスした時
      // this.componentState = COMPONENT_STATES.DEFAULT
      this.$emit('authorized', user)
    },

    resetData() {
      this.componentState = COMPONENT_STATES.DEFAULT
      this.$emit('cancel')
    },
  },
}

</script>

<style lang="scss">
</style>
