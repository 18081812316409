import {
  getAuth,
  AuthErrorCodes,
} from "firebase/auth";
import AlertService from '@/services/alertservice'

export function hasCurrentUser() {
  const auth = getAuth()
  return !!auth.currentUser
}

export function showErrorMessageByCode(errorCode) {
  const message = getErrorMessageByCode(errorCode)
  AlertService.showError(message)
}

export function getErrorMessageByCode(errorCode) {
  // firebaseのエラーコードからメッセージを返す
  switch (errorCode) {
    case AuthErrorCodes.INVALID_CODE:
      return '確認コードが正しくありません'
    case AuthErrorCodes.EXPIRED_OOB_CODE:
      return '認証に失敗しました、有効期限が切れています'
    case AuthErrorCodes.USER_DISABLED:
      return '認証に失敗しました、ユーザが無効です'
    case AuthErrorCodes.INVALID_OOB_CODE:
      return '認証に失敗しました、URLの値が正しくありません'
    case AuthErrorCodes.INVALID_EMAIL:
    case AuthErrorCodes.INVALID_PASSWORD:
    case AuthErrorCodes.USER_DELETED:
      return '認証に失敗しました、入力内容をご確認ください'
    case AuthErrorCodes.TOKEN_EXPIRED:
    case AuthErrorCodes.CODE_EXPIRED:
    // TODO: CODE_EXPIREDは連続して失敗した場合も発生するので別途メッセージ用意する
    case AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN:
      return '認証の有効期限が切れました、リロードして再度お試しください'
    case AuthErrorCodes.INVALID_PHONE_NUMBER:
      return '電話番号が不正な値です'
    case AuthErrorCodes.SECOND_FACTOR_ALREADY_ENROLLED:
      return 'すでに登録済みの電話番号です'
    case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
      return '認証の試行回数が多すぎます、しばらく待ってから再度実行してください'
    default:
      return `エラーが発生しました ${errorCode || ''}`
  }
}

export function formatFullPhoneNumber(number) {
  // 記号を省いた国番号付きの電話番号を返す
  const phoneNumber = number.replace(/\(|\)|\-/g, '')
  // +があれば国番号を追加しない
  if (phoneNumber.indexOf('+') !== -1) {
    return phoneNumber.trim()
  }
  return ('+81' + phoneNumber.trim())
}
