<template>
  <v-snackbar v-model="isActive" text :color="notifyType" :timeout="timeout">
    <v-icon v-if="notifyType == 'success'" color="success" class="mr-4">check_circle</v-icon>
    <v-icon v-else-if="notifyType == 'warning'" color="warning" class="mr-4">warning</v-icon>
    <v-icon v-else-if="notifyType == 'error'" color="error" class="mr-4">error</v-icon>
    <span v-html="message"></span>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: ''
    },
    notifyType: {
      type: String,
      default: 'success'
    },
    timeout: {
      type: Number,
      default: 3000
    },
  },
  data() {
    return {
      isActive: false
    }
  },
  watch: {
    "isActive"(value) {
      if (!value) {
        this.destroy()
      }
    }
  },
  created() {
    this.isActive = true
  },
  methods: {
    destroy() {
      // 即座にdomを削除するとtransitionする前に消えてしまうので、200ms待つ
      setTimeout(() => {
          this.$el.parentNode.removeChild(this.$el)
          // TODO: $destroyはvue3では無し、マウント元が消えればdestroyは不要か？
          // this.$destroy()
      }, 200)
    }
  },
}
</script>
