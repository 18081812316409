<template>
  <v-dialog :modelValue="modelValue" scrollable persistent :max-width="maxWidth">
    <v-card>
      <v-card-title class="card-title">
        ユーザ追加
        <v-btn absolute top right icon @click="close"><v-icon>close</v-icon></v-btn>
      </v-card-title>
      <v-divider class="mt-0 mb-4"></v-divider>
      <v-card-text>
        ユーザを作成しました。<br/>
        初期パスワードは再表示できません、控えてから画面を閉じてください。
        <v-form class="mt-4">
          <v-text-field
            v-model="user.email"
            label="メールアドレス"
            density="compact"
            readonly
          >
          </v-text-field>
          <v-text-field
            v-model="user.initial_password"
            label="初期パスワード"
            density="compact"
            persistent-hint
            @click:append="copyPassword"
            :hint="copied ? 'コピーしました' : ''"
            :append-icon="copied ? 'mdi:mdi-check' : 'mdi:mdi-content-copy'"
            readonly
            >
          </v-text-field>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  emits: [
    'update:modelValue',
  ],
  data(){
    return {
      copied: false
    }
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: ()=> ({
        email: '',
        initial_password: '',
      })
    }
  },
  methods: {
    close() {
      this.copied = false
      this.$emit("update:modelValue", false)
    },
    copyPassword() {
      navigator.clipboard.writeText(this.user.initial_password)
        .then(()=> {
          this.copied = true
          setTimeout(()=>{
            this.copied = false
          }, 4000)
        })
    }
  },
  computed: {
    maxWidth() {
      return this.wideModal ? "" : "600px"
    }
  }
};
</script>
