<template>
  <v-form ref="loginForm" v-model="valid" @submit.prevent >
    <div class="accountform mt-4">
      <v-text-field
        v-model="email"
        ref="loginEmail"
        name="identifier"
        label="メールアドレス"
        autocomplete="email"
        :rules="[rules.required, rules.email]"
        color="primary"
      >
      </v-text-field>
      <v-text-field
        v-model="password"
        ref="loginPass"
        type="password"
        name="password"
        label="パスワード"
        autocomplete="current-password"
        :rules="[rules.required, rules.min6]"
        color="primary"
      >
      </v-text-field>
      <slot class="mb-2" name="recaptcha"></slot>
      <v-btn
        type="submit"
        color="primary"
        size="large"
        block
        :loading="isLoading"
        :disabled="!valid || isLoading || !hasRecaptchaToken"
        @click="onLogin"
      >
        ログイン
      </v-btn>
    </div>
    <v-btn
      class="mt-2"
      color="primary"
      variant="text"
      @click="resetPassword"
    >
      パスワード再設定
    </v-btn>
  </v-form>
</template>

<script>
import {
  getAuth,
  signInWithEmailAndPassword,
  AuthErrorCodes,
  GoogleAuthProvider,
  signInWithRedirect
} from "firebase/auth";
import rules from "@/utils/rules"

export default {
  mixins: [],
  components: {},
  emits: [
    'authorized',
    'sendEmailVerification',
    'mfaRequired',
    'resetPassword',
    'error',
  ],
  props: {
    forcedMultiFactor: {  // ２段階認証強制モード
      type: Boolean,
      default: false,
    },
    recaptchaVerifier: {
      type: Object,
      default: null
    },
    hasRecaptchaToken: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      auth: getAuth(),
      idToken: '',              // Firebaseログイン後のtoken
      email: '',                // メールアドレス
      password: '',             // パスワード
      valid: true,              // フォーム入力内容が正常か
      user: {},                 // Firebaseから返されるuser
      hintNumber: '',           // 確認コードを送信した電話番号（新規登録以外は末尾のみ）
      isLoading: false,         // 読み込み中フラグ
      rules: rules,
    }
  },
  mounted() {},
  methods: {
    async onLogin() {
      // recaptchaが渡されていれば有効か確認
      if(this.recaptchaVerifier) {
        const recaptchaToken = await this.recaptchaVerifier.verify()
        if(!recaptchaToken) return
      }
      if(!this.$refs.loginForm.validate()) {
        return
      }
      this.isLoading = true
      // Firebase email+passwordログイン
      signInWithEmailAndPassword(this.auth, this.email.trim(), this.password.trim())
        .then((userCredential, error) => {
          this.isLoading = false
          // MFAが有効になっていない場合、ログイン成功する。電話番号を登録させる。
          this.user = userCredential.user
          if(this.forcedMultiFactor && !userCredential.user.emailVerified) {
            // メールアドレスの認証が済んでいない場合は二段階認証が設定できない
            this.$emit('sendEmailVerification', this.user)
            return
          }

          this.$emit('authorized', this.user)
        })
        .catch(async(error) => {
          this.isLoading = false
          if (error.code == AuthErrorCodes.MFA_REQUIRED) {
            // MFAが有効なユーザの場合このエラーが返る
            this.$emit('mfaRequired', error)
            return
          }
          this.$emit('error', error)
        })
    },
    resetPassword() {
      this.$emit('resetPassword')
    },
    signWithGoogle() {
      const provider = new GoogleAuthProvider();
      signInWithRedirect(this.auth, provider)
    },
  },
}
</script>

<style lang="scss">
  .firebaseui-idp-icon {
    height: 18px;
    vertical-align: middle;
    width: 18px;
  }
</style>
