<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="slide-y-transition"
    offset-y
    min-width="auto"
    class="colo-picker"
  >
    <template v-slot:activator="{ props }">
      <v-btn class="swatch" v-bind="props" depressed width="4rem" small :color="modelValue"></v-btn>
    </template>

    <div class="color-picker-wp">
      <v-color-picker
        dot-size="16"
        v-model="color"
      ></v-color-picker>
      <div class="pa-2 d-flex justify-end">
        <v-btn class="mr-2" text @click="menu = false">キャンセル</v-btn>
        <v-btn depressed color="primary" @click="updateValue">決定</v-btn>
      </div>
    </div>
  </v-menu>
</template>

<script>

import {parse, format} from 'date-fns'

export default {
  props:{
    modelValue: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  emits: [
    'update:modelValue'
  ],
  data() {
    return {
      menu: false,
      color: "#eeeff",
    }
  },
  mounted() {
    this.color = this.modelValue ? this.modelValue : "#eeeff"
  },
  methods: {
    updateValue() {
      this.$emit('update:modelValue', this.color)
      this.menu = false
    },
  }
}
</script>
<style scoped lang="scss">
.color-picker-wp {
  background-color: #fff;
}
.swatch {
  border: solid 1px #ccc !important;
}
</style>
