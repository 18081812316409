<template>
  <v-card-text>
    <v-form ref="form" @submit.prevent class="account-form">
      <!-- 自動入力対策 -->
      <input type="password" name="fake-password" value="" style="display:none">

      <v-row class="mt-4">
        <v-col>
          <v-text-field
            v-if="isCreate"
            v-model="email"
            label="メールアドレス"
            type="name"
            name="username"
            color="primary"
            :rules="[rules.required, rules.email]"
          />

          <template v-if="isCreate">
            <v-text-field
              v-model="password"
              label="パスワード(任意)"
              type="password"
              name="password"
              autocomplete="new-password"
              color="primary"
              :rules="[rules.min6, rules.invalidPasswordCharactor]"
            >
            </v-text-field>
            <p class="mb-3">
              ※パスワードが未入力の場合はランダムで生成されます
            </p>
          </template>
          <template v-else>
            <v-checkbox
              class="mt-0"
              v-model="is_active"
              label="有効"
              color="primary"
            >
            </v-checkbox>
          </template>

          <v-radio-group
            v-model="is_staff"
            color="primary"
            inline
          >
            <template v-slot:label>
              <p class="mt-2">権限</p>
            </template>
            <v-radio label="管理者" :value="true"></v-radio>
            <v-radio label="ユーザ" :value="false" checked></v-radio>
          </v-radio-group>

        </v-col>
      </v-row>
      <v-divider class="mb-4 mx-n6 bottom-divider"></v-divider>
      <div class="d-flex justify-end">
        <v-btn class="ml-2" variant="tonal" @click="formHandler('cancel')">キャンセル</v-btn>
        <v-btn class="ml-2" variant="flat" color="primary" type="submit" @click="submit" >{{isCreate ? '作成' : '更新'}}</v-btn>
      </div>
    </v-form>
  </v-card-text>
</template>

<script>
import rules from "@/utils/rules"
const INITIAL_DATA = {
  email: '',               // String メールアドレス
  password: null,
  is_staff: false,            // Boolean 管理者フラグ
  is_active: false            // Boolean 有効　/　無効（削除とは別）
}

export default {
  props:{
    formData: {
      type: Object,
      default: () => ({...INITIAL_DATA}),
    },
    formHandler: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      rules: rules,
      ...INITIAL_DATA,
    }
  },
  mounted() {
    if(this.formData) {
      this.setFormData(this.formData)
    }
  },
  computed: {
    isCreate() {
      return !this.formData
    },
    params() {
      // API用に変換
      const params = {
        email: this.email,
        password: this.password || null,
        is_staff: this.is_staff,
        is_active: this.is_active,
      }

      if (this.isCreate) {
        params.is_active = true
      }

      return params
    },
  },
  methods: {
    // 取得したデータをフォーム用に変換
    setFormData(data) {
      this.email = data.email;
      this.is_staff = data.is_staff;
      this.is_active = data.is_active
    },
    submit() {
      if(this.$refs.form.validate()) {
        this.formHandler('submit', this.params)
      }
    }
  }
}
</script>

<style lang="scss">
.account-form {
  .v-radio-group > .v-input__control {
      flex-direction: row;
  }
}
</style>
