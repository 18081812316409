<template>
  <v-form ref="phoneForm" v-model="valid" @submit.prevent >
    <h4 class="content-title mb-4">
      二段階認証の設定
    </h4>
    <div class="accountform px-2">
      <div class="mb-4">
        SMSが受信できる電話番号を<br>
        登録してください。
      </div>
      <v-text-field
        v-model="phoneNumber"
        type="tel"
        name="phoneNumber"
        label="電話番号"
        autocomplete="tel"
        placeholder="09012345678"
        density="compact"
        :rules="[rules.required]"
        color="primary"
      ></v-text-field>
      <div class="d-flex align-center justify-space-between">
        <v-btn
          width="48%"
          @click="prevState"
          depressed>
          キャンセル
        </v-btn>
        <v-btn
          width="48%"
          type="submit"
          depressed
          color="primary"
          @click="onRegisterPhoneNumber"
          :disabled="!valid || isLoading"
        >
          電話番号を登録
        </v-btn>
      </div>
    </div>
  </v-form>
</template>

<script>
import {
  getAuth,
  multiFactor,
  AuthErrorCodes,
  PhoneAuthProvider,
  RecaptchaVerifier
} from "firebase/auth";
import { formatFullPhoneNumber } from '@/utils/firebase'
import rules from "@/utils/rules"

export default {
  mixins: [],
  components: {},
  emits: [
    'verifyPhoneNumber',
    'prevState',
    'error',
  ],
  props: {
    user: {
      type: Object,
      required: true,
    },
    recaptchaVerifier: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      auth: getAuth(),
      phoneNumber: '',          // 電話番号
      valid: true,              // フォーム入力内容が正常か
      isLoading: false,         // 読み込み中フラグ

      rules: rules,
    }
  },
  mounted() {
  },
  methods: {
    prevState() {
      this.$emit('prevState')
    },

    async onRegisterPhoneNumber() {
      // 現在のユーザのMFA用電話番号を設定する
      // if(!this.$refs.phoneForm.validate()) return
      this.isLoading = true
      this.multiFactorUser = multiFactor(this.user)
      const multiFactorSession = await this.multiFactorUser.getSession()
      const phoneInfoOptions = {
        phoneNumber: formatFullPhoneNumber(this.phoneNumber),
        session: multiFactorSession
      }
      const phoneAuthProvider = new PhoneAuthProvider(this.auth)
      await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier)
        .then(verificationId => {
          this.$emit('verifyPhoneNumber', this.phoneNumber, verificationId)
        })
        .catch(error => {
          this.$emit('error', error)
        })
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss">
</style>
