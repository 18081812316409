<template>
  <v-dialog v-model="dialog" max-width="380">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        variant="plain"
        :color="iconColor"
        :icon="icon"
        :size="iconSize"
      >
        <v-icon v-if="icon" :icon="iconName" />
        <span v-else v-text="buttonText"></span>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="content-title" v-text="title"></v-card-title>
      <v-card-text v-text="message"></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click.native="dialog= false"
          :color="cancelColor"
          width="120"
          v-text="cancel">
        </v-btn>
        <v-btn
          @click.native="onSubmit"
          :color="submitColor"
          class="ml-2"
          width="120"
          v-text="submit">
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    callback: {
      type: Function,
      required: true
    },
    title: {
      type: String,
      default: '確認'
    },
    message: {
      type: String,
      default: '本当によろしいですか？'
    },
    cancel: {
      type: String,
      default: 'キャンセル'
    },
    submit: {
      type: String,
      default: 'OK'
    },
    buttonText: {
      type: String,
      default: '削除',
    },
    large: {
      type: Boolean,
      default: false
    },
    depressed: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false
    },
    iconName: {
      type: String,
      default: 'delete'
    },
    iconColor: {
      type: String,
      default: 'error',
    },
    submitColor: {
      type: String,
      default: 'primary',
    },
    cancelColor: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    iconSize(){
      if(this.small) {
        return 'x-small'
      }
      if(this.large) {
        return 'large'
      }
      return 'small'
    }
  },
  methods: {
    onSubmit() {
      this.dialog = false
      this.callback()
    }
  }
}
</script>
