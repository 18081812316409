<template>
  <v-form ref="codeForm" v-model="valid" @submit.prevent >
    <div class="accountform">
      <v-card-text>
        <h4 class="content-title mb-4">
          確認コードの入力
        </h4>
        <div class="mb-2">
          {{hintNumber}} に送信された確認コードを入力してください
        </div>
        <div style="max-width: 300px" class="mx-auto">
          <v-text-field
            type="tel"
            v-model="verifyCode"
            label="確認コード"
            autocomplete="off"
            length="6"
            density="compact"
            @change="onSubmitCode"
            color="primary"
          ></v-text-field>
        </div>
        <div class="mt-4 d-flex align-center justify-space-between">
          <v-btn
            width="48%"
            @click="prevState"
            depressed>
            キャンセル
          </v-btn>
          <v-btn
            width="48%"
            type="submit"
            color="primary"
            @click="onSubmitCode"
            :loading="isLoading"
            :disabled="verifyCode.length < 6"
            depressed
          >
            コード確認
          </v-btn>
        </div>
      </v-card-text>
    </div>
  </v-form>
</template>

<script>
import {
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  multiFactor
} from "firebase/auth";

export default {
  mixins: [],
  components: {},
  emits: [
    'authorized',
    'prevState',
    'error',
  ],
  props: {
    user: {
      type: Object,
      required: false,  // 電話番号登録時の認証モードでは必須
    },
    resolver: {  // multiFactorResolver オブジェクト
      type: Object,
      required: false,
    },
    registerMode: {  // 電話番号登録時の認証モード
      type: Boolean,
      required: false,
      default: false,
    },
    verificationId: {
      type: String,
      required: true,
    },
    hintNumber: {           // 確認コードを送信した電話番号（新規登録以外は末尾のみ）
      type: String,
      required: true,
    }
  },
  data() {
    return {
      verifyCode: '',           // SMS認証コード
      valid: true,              // フォーム入力内容が正常か
      isLoading: false,         // 読み込み中フラグ
    }
  },
  methods: {
    prevState() {
      this.$emit('prevState')
    },
    async onSubmitCode() {
      if(this.verifyCode.length < 6) return

      // 第2要素認証 SMSコード確認
      this.isLoading = true
      this.verifyCode = this.formatZenkakuToHankaku(this.verifyCode)
      const authCredential = PhoneAuthProvider.credential(this.verificationId , this.verifyCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(authCredential);

      if(this.registerMode) {
        await this.onRegisterEnterCode(multiFactorAssertion)
        this.isLoading = false
      } else {
        // ログインが成功した場合は遷移までloadingを維持する
        this.onMfaSignIn(multiFactorAssertion)
      }

    },
    onMfaSignIn(multiFactorAssertion) {
      // 第2要素認証 SMSコード確認
      this.resolver.resolveSignIn(multiFactorAssertion)
        .then(async(userCredential) => {
          // 二段階認証が通ればそのままログインする
          const user = userCredential.user
          this.$emit('authorized', user)
        })
        .catch(error=> {
          this.isLoading = false
          this.$emit('error', error)
        })
    },
    async onRegisterEnterCode(multiFactorAssertion) {
      // onRegisterPhoneNumberで送信されたコードからユーザにMFAを追加しログインする
      const phoneNumberName = '初期登録番号';
      const multiFactorUser = multiFactor(this.user)
      await multiFactorUser.enroll(multiFactorAssertion, phoneNumberName)
        .then(()=> {
          // MFA登録成功
          this.$emit('authorized', this.user)
        })
        .catch(error => {
          this.$emit('error', error)
        })

    },
     formatZenkakuToHankaku(str) {
      if(!str) return
      return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
      });
    },
  },
}
</script>

<style lang="scss">
</style>
