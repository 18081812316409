import vuetify from '@/plugins/vuetify'
import ConsoleMessage from '@/components/console/ConsoleMessage'
import { createApp } from "vue";

export default {

  methods: {
    message(message, notifyType) {
      return new Promise((resolve) => {
        const option = {
          message: message,
          notifyType: notifyType,
          ok: () => {
            return resolve(true);
          },
          cancel: () => {
            return resolve(false);
          }
        }
        // マウントさせるためのelementを作成する、
        // このelementはコンポーネントを閉じた際にConsoleConfirmから破棄される
        const el = document.createElement('div')
        document.getElementsByTagName('body')[0].appendChild(el)
        createApp(ConsoleMessage, option).use(vuetify).mount(el)
      })
    }
  }
}