<template>
  <section>
    <slot name="control">
      <v-row>
        <v-col>
          <v-btn depressed color="primary" @click="openCreateModal">{{itemName}}を追加</v-btn>
        </v-col>
      </v-row>
    </slot>
    <slot name="search" :submit="submitSearch" :model="text">
      <v-row class="mb-1">
        <v-col cols="6">
          <ConsoleSearch
            v-model="text"
            @submit="submitSearch"
            :searchByButton="searchByButton"
          />
        </v-col>
      </v-row>
    </slot>
    <ConsoleTable
      :service="service"
      :limit="limit"
      :fields="fields"
      :formHandler="formHandler"
      :tableActions="tableActions"
      :rowClickAction="rowClickAction"
      :rowClassConditions="rowClassConditions"
      :currentPage="page"
      @pageChange="pageChange"
      @sortChange="sortChange"
      @edit="openEditModal"
      @delete="deleteItem"
    />
    <ConsoleModal v-model="openModal" ref="formModal" :title="formTitle" :wideModal="modalType == 'wide'" :fullModal="modalType == 'full'">
      <template v-if="openModal">
        <slot name="form"
          :formData="formData"
          :formHandler="formHandler"
        >
        </slot>
      </template>
      <LoadingOverlay :isActive="service.isLoading" />
    </ConsoleModal>

    <v-snackbar v-model="showNotify" variant="tonal" :color="notifyType">
      <v-icon v-if="notifyType == 'success'" color="success" class="mr-4">check_circle</v-icon>
      <v-icon v-else-if="notifyType == 'warning'" color="warning" class="mr-4">warning</v-icon>
      <v-icon v-else-if="notifyType == 'error'" color="error" class="mr-4">error</v-icon>
      <span v-html="notifyMessage"></span>
    </v-snackbar>
  </section>
</template>

<script>
import ConsoleTable from '../../components/console/ConsoleTable';
import ConsoleSearch from '../../components/console/ConsoleSearch';
import ConsoleModal from '../../components/console/ConsoleModal';
import LoadingOverlay from '@/components/LoadingOverlay';
import Confirm from '@/mixins/confirm';

export default {
  components: {
    ConsoleTable,
    ConsoleSearch,
    ConsoleModal,
    LoadingOverlay
  },
  mixins:[Confirm],
  props: {
    itemName: {
      // 項目名
      type: String,
      default: '項目',
    },

    service: {
      // restservice のインスタンス
      type: Object,
      required: true
    },

    fields: {
      /**
       * テーブルのカラムを指定
       * @param {Number}  id
       * @param {String}  label       列名
       * @param {String}  key         列キー
       * @param {String}  type        型の指定 [string | boolean | select | date | datetime]
       * @param {Boolean} sortable    列で並び替えを許可するか
       * @param {Object}  options     type = selectの際の文字列 { 0: 'AB', 1: 'BC', ...}
       * @param {Array}   boolText    type = booleanの際の文字列 ['無効', '有効']
       */
      type: Array,
      default: () => ([
        { id: 1, label: '#', key: 'id', type: 'string', sortable: true},
        { id: 2, label: 'ステータス', key: 'type', type: 'select', sortable: false, options: {0: 'オフ', 1: 'オン'}},
      ])
    },

    validations: {
      /**
       * フォーム内容の検証内容を指定
       * @param {String}   key         検証するキー
       * @param {String}   label       キーの項目名
       * @param {Function} condition   条件(falseでエラー)
       * @param {String}   errorText   エラーの内容
       * 例: [
       *  { key: 'name', label: '名前', condition: (name) => !!String(name).length, errorText: '未入力' },
       *  { key: 'name', label: 'パスワード', condition: (p) => String(p).length >= 8, errorText: '8文字未満' }
       * ]
       */
      type: Array,
      default: () => ([])
    },

    tableActions: {
      /**
       * テーブルの操作メニュー
       * @param {String}   label  表示するリンクテキスト
       * @param {String}   action edit | delete | custom
       * @param {Function} func   customの際に実行する関数、引数にidが渡される
       */
      type: Array,
      default: () => ([
        {label: '編集', action: 'edit'},
        {label: '削除', action: 'delete'},
      ])
    },

    rowClickAction: {
      /**
       * テーブル行をクリックした時の操作
       * @param {String}   label  表示するリンクテキスト
       * @param {String}   action edit | delete | custom
       * @param {Function} func   customの際に実行する関数、引数にidが渡される
       */
       type: Object,
       default: () => ({})
    },

    rowClassConditions: {
      /**
       * テーブルのスタイル調整を行うためプロパティ
       * conditionにtrueを返した行に対してクラス名を追加する
       * @param {Function} condition 条件式
       * @param {String}   className 追加するクラス名
       *
       * 例:
       *  [{
       *   condition: r => r.id === 1,
       *   className: "uk-text-primary"
       *  }]
       */
      type: Array,
      default: () => []
    },

    limit: {
      // 1ページの取得件数
      type: Number,
      default: 50
    },

    searchByButton: {
      // 検索入力時の自動検索を停止してsubmit時のみ検索させる
      // デフォルトのキーワード検索のみ有効
      type: Boolean,
      default: false,
    },

    customQuery: {
      // 独自の検索条件などを使用した場合に追加するクエリ
      // 例: {is_official: this.isOfficial, status: 1}
      type: Object,
      default: () => {},
    },

    modalType: {
      // 作成・編集用モーダルのレイアウトを変更する
      // wide | full
      type: String,
      default: ''
    },

    createdCallback: {
      // 項目新規作成後に実行する関数
      type: Function,
    }
},
  data() {
    return {
      openModal: false,   // Boolean モーダル表示フラグ
      editID: null,       // Number 編集項目ID
      formData: null,     // Object 編集画面に表示するデータ
      page: 1,            // Number 一覧のページ番号
      order: '',          // String 一覧のソート条件
      text: '',           // String 絞り込みテキスト

      showNotify: false,  // メッセージ表示フラグ
      notifyMessage: "",  // メッセージの内容
      notifyType: "",      // メッセージタイプ success | error | warning
    }
  },
  mounted() {
    this.service.list(this.query);
  },
  beforeDestory() {
  },
  computed: {
    formTitle() {
      return !!this.editID ? `${this.itemName}編集` : `${this.itemName}追加`;
    },
    query() {
      return {
        text: this.text,
        page: this.page,
        limit: this.limit,
        order: this.order,
        ...this.customQuery
      }
    },
    serviceError() {
      return this.service.error
    }
  },
  watch: {
    serviceError() {
      if(!!this.serviceError.length) {
        this.errorNotification(this.serviceError)
      }
    }
  },
  methods: {
    setModalClose() {
      this.openModal = false
      this.editID = null
    },
    openCreateModal() {
      this.editID = null
      this.formData = null
      this.openModal = true
    },
    async openEditModal(id) {
      await this.service.get(id).then(response => {
        this.formData = response.data;
        this.editID = id;
        this.openModal = true;
      })
    },
    async updateData(id, data) {
      this.openModal = false
      if(id) {
        const updatedItems = [...this.service.items];
        const index = updatedItems.map(c=>c.id).indexOf(id);
        updatedItems[index] = data;
        this.service.items = updatedItems;
        this.successNotification('更新しました');
      } else {
        // Createの場合は一覧を再取得する
        await this.service.list(this.query);
        this.successNotification('作成しました');
        this.createdCallback(data)
      }
    },
    async submitSearch(overrideQuery = {}) {
      this.page = 1;
      const query = {
        ...this.query,
        ...overrideQuery
      }
      await this.service.list(query);
    },
    async pageChange(page) {
      this.page = page;
      await this.service.list(this.query);
    },
    async sortChange(field, order) {
      // 降順の場合はフィールドの頭に-を付ける
      const prefix = order === 'desc' ? '-' : '';
      this.order = `${prefix}${field}`;
      await this.service.list(this.query);
    },

    formHandler(type, params={}) {
      if(type === 'submit') {
        this.submitForm(params);
      }
      if(type === 'cancel') {
        this.openModal = false
      }
    },

    getItemName(id) {
      // テーブル2列からレコードの名前を作成する
      const item = this.service.items.find(i => i.id === id)
      const key1 = this.fields[0].key
      const key2 = this.fields[1]?.key
      if(typeof(item[key2]) == 'string') {
        return `${item[key1]} ${item[key2]}`
      }
      return item[key1]
    },

    async deleteItem(id) {
      const name = this.getItemName(id)
      if (await this.confirm("削除", `${name}を削除します、本当によろしいですか？`, "error")) {
        await this.service.delete(id);
        await this.service.list(this.query);
        this.successNotification("削除しました")
      }
    },

    async submitForm(params) {
      const errors = this.validateParams(params)
      if(errors.length){
        this.validateNotification(errors)
        return
      }
      let response;
      if(this.editID) {
        response = await this.service.update(this.editID, params)
      } else {
        response = await this.service.create(params)
      }
      if(response.data) {
        if(response.data.errors) {
          this.validateNotification(response.data.errors)
          return
        }
        const status = String(response.status)
        if(status.match(/^20\d$/)) {
          this.updateData(this.editID, response.data);
        }
      }
    },

    validateParams(params){
      const errors = []
      this.validations.forEach(v => {
        const valid = v.condition(params[v.key])
        if(!valid) {
          errors.push(`${v.label||v.key}が${v.errorText||'不正な値'}です。`);
        }
      })
      return errors
    },


    // TODO: 通知処理を他コンポーネント含めてまとめる
    successNotification(message) {
      this.notifyType = "success"
      this.notifyMessage = message
      this.showNotify = true
    },
    errorNotification(error) {
      const message = error?.message
      this.notifyType = "error"
      this.notifyMessage = message || "エラーが発生しました しばらくしてからもう一度お試しください。"
      this.showNotify = true
    },
    validateNotification(errors) {
      this.notifyType = "error"
      this.notifyMessage = errors.join('<br>')
      this.showNotify = true
    }
  }
};
</script>
