import RestService from './restservice'

export default class AnalyticsService extends RestService {
  constructor(url) {
    super(url);
  }

  async fetchRankings(params) {
    return this.httpclientWrapper(this.httpclient.get(`${this.API_URL}analytics`, {params}))
      .finally(()=> {
        this.isLoading = false;
      })
  }

  async fetchRanking(params) {
    return this.httpclientWrapper(this.httpclient.get(`${this.API_URL}events`, {params}))
      .finally(()=> {
        this.isLoading = false;
      })
  }

  async fetchCategories(params) {
    return this.httpclientWrapper(this.httpclient.get(`${this.API_URL}products/categories`, {params}))
      .finally(()=> {
        this.isLoading = false;
      })
  }

  async fetchBrands(params) {
    return this.httpclientWrapper(this.httpclient.get(`${this.API_URL}products/brands`, {params}))
      .finally(()=> {
        this.isLoading = false;
      })
  }
}