<template>
  <div>
    <div v-if="succeeded">
      追加された二段階認証を取り消しました
    </div>
    <div v-else-if="failed">
      リクエストの処理に失敗しました
    </div>

    <LoadingBar v-else />
  </div>
</template>

<script>

import {
  getAuth,
  checkActionCode,
  applyActionCode
} from "firebase/auth";
import { showErrorMessageByCode } from '@/utils/firebase'
import LoadingBar from '@/components/LoadingBar'

export default {
  components: {
    LoadingBar
  },
  mounted() {
    this.revertFactor()
  },
  props: {
    oobCode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      auth: getAuth(),
      succeeded: false,
      failed: false,
    }
  },
  computed: {
  },
  methods: {
    revertFactor() {
      checkActionCode(this.auth, this.oobCode)
        .then((info) => {
          return applyActionCode(this.auth, this.oobCode)
        }).then(() => {
          this.succeeded = true
        })
        .catch(e => {
          showErrorMessageByCode(e.code)
          this.failed = true
        })
    }
  }
}
</script>
