const DEFAULT_DURATION = 8000
const ALERT_TYPES = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
}

export let alert = {
  show: false,
  message: '',
  type: 'info',
  duration: 0,
  callback: null,
}

let triggerCallback;

export default {
  setListener: (callback) => {
    // vuex の代わりとして無理やり
    triggerCallback = callback
  },

  showInfo: (message, callback, duration) => {
    showAlert(message, callback, duration, ALERT_TYPES.INFO)
  },
  showSuccess: (message, callback, duration) => {
    showAlert(message, callback, duration, ALERT_TYPES.SUCCESS)
  },
  showWarning: (message, callback, duration) => {
    showAlert(message, callback, duration, ALERT_TYPES.WARNING)
  },
  showError: (message, callback, duration) => {
    showAlert(message, callback, duration, ALERT_TYPES.ERROR)
  },
  hideAlert: () => {
    if (alert) {
      alert.show = false
    }
    update()
  }
}

function showAlert(message, callback, duration, type) {
  clear()
  alert.message = [message]
  if (callback) alert.callback = callback
  if (duration != null) alert.duration = duration
  alert.type = type
  alert.show = true
  update()
}

function clear() {
  alert.message = ''
  alert.callback = null
  alert.duration = DEFAULT_DURATION
  alert.type = ALERT_TYPES.INFO
}

function update() {
  if (!triggerCallback) return

  triggerCallback(alert)
}
