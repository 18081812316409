import HttpClient from '@/utils/httpclient'

export default class RestService {
  constructor(url) {
    this.API_URL = url;
    this.items = [];
    this.total = 0;
    this.httpclient = new HttpClient();
    this.error = '';
    this.isLoading = false;
  }

  httpclientWrapper(func) {
    this.isLoading = true;
    let err = ''
    return func
      .catch((error)=> {
        if(error.response?.data) {
          err = error.response.data;
        } else {
          err = error.message;
        }
        return error.response;
      })
      .finally(()=> {
        this.error = err
        this.isLoading = false;
      })
  }

  /**
   * @typedef Query
   * @property {String} text 検索文字列
   * @property {Number} limit 取得件数
   * @property {Number} page 取得ページ
   * @property {String} order 並び順
   */

  /**
   * @param {Query} params
   */
  async list(params) {
    return this.httpclientWrapper(this.httpclient.get(this.API_URL, {params}).then(
      response => {
        this.total = response.data.total;
        this.items = response.data.items;
        this.items.forEach((item, index) => {
          item.index = index + 1 + (params.page -1) * params.limit;
        })
      }
    ))
  }

  async get(id) {
    return this.httpclientWrapper(this.httpclient.get(this.API_URL + id))
  }
  async create(params) {
    return this.httpclientWrapper(this.httpclient.post(this.API_URL, params))
  }
  async update(id, params) {
    return this.httpclientWrapper(this.httpclient.put(this.API_URL + id, params))
  }
  async delete(id) {
    return this.httpclientWrapper(this.httpclient.delete(this.API_URL + id))
  }
}