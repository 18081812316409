<template>
  <div class="console-search">
    <div>
      <form @submit.prevent="$emit('submit')">
        <v-text-field
          v-model="searchText"
          color="primary"
          placeholder="検索"
          density="compact"
          hide-details
          variant="outlined"
          prepend-inner-icon="search"
          @input="onChange">
        </v-text-field>
        <v-btn depressed color="primary" v-if="searchByButton">検索</v-btn>
      </form>
    </div>
  </div>
</template>

<script>
import { _debounce } from '@/utils/common'
export default {
  emits: [
    'update:modelValue',
    'submit'
  ],
  props: {
    modelValue: {
      type: String,
      required: true
    },
    searchByButton: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    searchText: {
      get() {
        return this.modelValue
      },
      set(val) {
        return this.$emit('update:modelValue', val)
      }
    }
  },
  methods: {
    onChange: _debounce(function() {
      if(!this.searchByButton) {
        this.$emit('submit');
      }
    }),
  }
}
</script>
