<template>
  <v-form ref="passwordResetForm" v-model="valid" @submit.prevent >
    <h4 class="content-title mb-4">パスワード再設定</h4>
    <div class="mb-2">
      登録済みのメールアドレスを入力してください。<br>
      パスワード再設定のためのメールをお送りします。
    </div>
    <div class="accountform mt-6">
      <v-text-field
        v-model="email"
        type="email"
        label="メールアドレス"
        name="email"
        autocomplete="username"
        density="compact"
        :rules="[rules.required, rules.email]"
        color="primary"
      ></v-text-field>
      <div class="d-flex align-center justify-space-between">
        <v-btn
          width="48%"
          variant="tonal"
          @click="()=>prevState()"
          :disabled="isLoading"
        >
          キャンセル
        </v-btn>

        <v-btn
          width="48%"
          type="submit"
          color="primary"
          @click="onSendResetPasswordEmail"
          :loading="isLoading"
          :disabled="!valid"
        >
          再設定メールを送信
        </v-btn>
      </div>
    </div>
  </v-form>
</template>

<script>
import {
  getAuth,
  sendPasswordResetEmail,
} from "firebase/auth";
import rules from "@/utils/rules"

export default {
  mixins: [],
  components: {},
  data() {
    return {
      auth: getAuth(),
      idToken: '',              // Firebaseログイン後のtoken
      email: '',                // メールアドレス
      valid: true,              // フォーム入力内容が正常か
      isLoading: false,         // 読み込み中フラグ
      rules: rules,
    }
  },
  props: {
    prevState: {
      type: Function,
      required: true,
    }
  },
  mounted() {},
  methods: {
    async onSendResetPasswordEmail() {
      // パスワード設定メール送信
      this.isLoading = true
      await sendPasswordResetEmail(this.auth, this.email)
        .catch((error) => {
          // 未登録のメールアドレスでも成功と同様のメッセージを返す
        })
        .finally(()=> {
          this.isLoading = false
        })

      location.href = '/account/password_reset_done'
    },
  },
}
</script>

<style lang="scss">
</style>
