<template>
  <div class="pa-4">
    <h4 class="content-title mb-4">
      電話番号を選択
    </h4>
    <div class="mb-2">
      複数の電話番号が登録されています。<br>
      SMS認証に使用する電話番号を選択してください。
    </div>
    <div class="accountform mt-8">
      <v-select
        v-model="hint"
        :items="multiFactorHints"
        variant="outlined"
        density="compact"
        label="使用する電話番号"
        item-title="phoneNumber"
        :item-value="item => item"
      >
      </v-select>
      <div class="text-right">
        <v-spacer></v-spacer>
        <v-btn
          @click="prevState"
          depressed>
          キャンセル
        </v-btn>
        <v-btn
          depressed
          color="primary"
          @click="select"
          :disabled="isLoading"
        >
          送信
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mixins: [],
  emits: [
    'select',
    'prevState',
  ],
  props: {
    multiFactorHints: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      valid: false,
      isLoading: false,
      hint: {},
    }
  },
  mounted() {
    this.hint = this.multiFactorHints[0]
  },
  methods: {
    prevState() {
      this.$emit('prevState')
    },
    select() {
      this.isLoading = true
      this.$emit('select', this.hint)
    },
  },
}
</script>

<style lang="scss">
</style>
