<template>
  <v-dialog :modelValue="modelValue" width="400" persistent>
    <v-card class="pb-4">
      <v-card-title>
        {{title}}
      </v-card-title>
      <v-divider class="mt-0 mb-4"></v-divider>
      <MultiFactor
        v-if="modelValue"
        :resolver="resolver"
        :user="user"
        :recaptchaVerifier="recaptchaVerifier"
        :errorCallback="errorCallback"
        @authorized="authorized"
        @cancel="closeDialog"
      />
    </v-card>
  </v-dialog>
</template>
<script>
/**
 * ログイン後にMFA認証を必要とする操作を行なった際に表示するダイアログ
*/
import MultiFactor from '@/components/firebase/MultiFactor'

export default {
  mixins: [],
  components: {
    MultiFactor,
  },
  emits: [
    'update:modelValue'
  ],
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: "二段階認証",
    },
    resolver: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: false,
    },
    recaptchaVerifier: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
    errorCallback: {
      type: Function,
      required: false,
    },
    next: {
      // 認証完了後のcallback
      type: Function,
      default: ()=>{}
    }
  },
  data() {
    return {
    }
  },
  methods: {
    authorized() {
      this.$emit('update:modelValue', false)
      this.next()
    },
    // updateDialog(v) {
    //   console.log(v)
    //   this.$emit('update:modelValue', v)
    // },
    closeDialog() {
      this.$emit('update:modelValue', false)
    }
  },
}

</script>

<style lang="scss">
</style>
