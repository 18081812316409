<template>
  <v-dialog :modelValue="modelValue" :fullscreen="fullModal" :max-width="maxWidth" scrollable persistent>
    <v-card>
      <v-card-title class="card-title" v-if="title">
        <span class="title">{{ title }}</span>
        <v-btn absolute top right icon @click="close"><v-icon>close</v-icon></v-btn>
      </v-card-title>
      <v-divider class="mt-0"></v-divider>
      <slot></slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  emits: [
    'update:modelValue'
  ],
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    wideModal: {
      type: Boolean,
      default: false
    },
    fullModal: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close() {
      this.$emit("update:modelValue", false)
    }
  },
  computed: {
    maxWidth() {
      return this.wideModal ? "" : "600px"
    }
  }
};
</script>

<style lang="scss">
  .card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      padding-left: .5rem;
    }
  }
  .v-label {
    opacity: 1;
  }
</style>
