<template>
  <div class="recaptcha-wrapper mb-4">
    <LoadingBar v-if="loading && !invisible" />
    <div class="d-flex">
      <v-spacer></v-spacer>
      <div v-show="!loading" ref="recaptchaverifier"></div>
      <v-spacer></v-spacer>
    </div>
  </div>
</template>

<script>
import {
  getAuth,
  RecaptchaVerifier,
} from "firebase/auth";
import LoadingBar from '@/components/LoadingBar'

export default {
  components: {
    LoadingBar
  },
  mounted() {
  },
  props: {
    invisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      auth: getAuth(),
      recaptchaVerifier: null,
      loading: false
    }
  },
  methods: {
    async initRecaptcha(verifyCallback) {
      await this.$nextTick()
      if(!this.recaptchaVerifier) {
        this.loading = true
        const recaptchaElm = this.$refs.recaptchaverifier
        this.recaptchaVerifier = new RecaptchaVerifier(recaptchaElm, {
          // size: invisibleを指定するとチェックボタンを非表示にできるが、状況によっては画像認証が入る
          'size': this.invisible ? 'invisible' : null,
          callback: (res)=> {
            if(verifyCallback) {
              verifyCallback(res)
            }
          }
        }, this.auth)
        await this.recaptchaVerifier.render()
        this.loading = false

      }
      return this.recaptchaVerifier
    },

  },
}
</script>
