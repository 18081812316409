<template>
  <v-card-text>
    <v-form v-model="valid" ref="pointForm" @submit.prevent="formHandler('submit', params)">
      <v-row class="mt-4">
        <v-col cols="7">
          <v-row>
            <v-col>
              <v-text-field v-model="key" density="compact" label="キー" @input="onChangeKey" :rules="chackDupricateKey" color="primary" disabled></v-text-field>
              <v-text-field :class="{'change': last_name && name != last_name}" v-model="name" density="compact" label="名称" color="primary" disabled></v-text-field>
              <v-text-field class="change" v-model="last_name" density="compact" label="前回の名称" color="primary" disabled v-if="last_name && name != last_name"></v-text-field>
              <v-text-field v-model="address" density="compact" label="住所" disabled></v-text-field>
              <v-text-field :class="{'change': last_address && address != last_address}" class="change" v-model="last_address" density="compact" label="前回の住所" disabled v-if="last_address && address != last_address"></v-text-field>
              <v-row>
                <v-col class="text-center pa-0">
                  <v-btn color="primary" variant="text" @click="addressToLatlng(address)">↓住所から緯度経度を取得</v-btn>
                </v-col>
                <v-col class="text-center pa-0">
                  <v-btn color="primary" variant="text" @click="addressToLatlng(address, name)">↓住所と名前から緯度経度を取得</v-btn>
                </v-col>
                <!-- <v-col class="text-center pa-0">
                  <v-btn text color="primary" @click="latlngToAddress">↑緯度経度から住所を取得</v-btn>
                </v-col> -->
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field v-model.number="latitude" density="compact" label="緯度" :rules="decimal" color="primary"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model.number="longitude" density="compact" label="経度" :rules="decimal" color="primary"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-divider class="mt-4"></v-divider>

          <v-row>
            <v-col>
              <v-checkbox v-model="is_active" label="公開中" color="primary" disabled></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="5">
          <div class="map-wrapper">
            <div id="map_canvas"></div>
            <div class="map-caption">
              マーカーのドラッグで緯度経度を修正できます。
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            v-for="(f, key) in extraFields"
            :key="key"
            :label="key"
            v-model="extraFields[key]"
            rows="2"
          ></v-textarea>
        </v-col>

      </v-row>
      <v-divider class="mb-4 mx-n6 bottom-divider"></v-divider>

      <v-row class="justify-end align-center">
        <v-col v-if="errorMessage">
          <p class="ma-0 error--text">{{errorMessage}}</p>
        </v-col>
        <v-col cols="auto">
          <v-btn class="ml-2" variant="tonal" @click="formHandler('cancel')">キャンセル</v-btn>
          <!-- <v-btn v-if="!isCreate" class="ml-4 mr-2" depressed color="error" @click="formHandler('delete', {id: formData.id})">削除</v-btn> -->
          <v-btn class="ml-2" variant="flat" color="primary" type="submit" :disabled="!valid">{{isCreate ? '作成' : '更新'}}</v-btn>
        </v-col>
      </v-row>

      <LoadingOverlay :isActive="isLoading" />
    </v-form>

  </v-card-text>
</template>

<script>
// import {parse} from 'date-fns';
import ConfirmMixin from '@/mixins/confirm'
import MessageMixin from '@/mixins/message'
import mapService from '@/mapservice';
import LoadingOverlay from '@/components/LoadingOverlay';
import OverlayMarker from '@/overlay_marker'
import HttpClient from '@/utils/httpclient'
// import constants from '@/constants'
const INITIAL_FORM_DATA = {
  'id': '',
  'key': '',
  'name': '',
  'last_name': '',
  'latitude': '',
  'longitude': '',
  'address': '',
  'last_address': '',
  'is_active': true,
}

export default {
  components: {
    LoadingOverlay,
  },
  mixins: [ConfirmMixin, MessageMixin],
  props:{
    formData: {
      type: Object,
      default: () => ({...INITIAL_FORM_DATA}),
    },
    formHandler: {
      type: Function,
      required: true
    },
    service: {
      type: Object,
      default: null,
    },
    errorMessage: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      ...INITIAL_FORM_DATA,
      extraFields: {},
      marker: null,
      latlng: {},
      isLoading: false,
      image_url: '',
      inputFile: '',

      isDupricateKey: false,
      valid: null,
      required: {
        required: v => !!v || '必須項目です',
      },
      decimal: [
        v => !!v || '必須項目です',
        v => !!/^[\d.]*$/.test(v) || '数値を入れてください',
      ],
      defaultZoom: 16
    }
  },
  mounted() {
    storelocator.map.extra_fields.forEach(f => {
      this.$set(this.extraFields, f, '')
    })

    if(this.formData) {
      this.setFormData(this.formData)
    }
    this.initMap()
  },
  beforeUnmount() {
    google.maps.event.clearInstanceListeners(this.marker);
  },
  computed: {
    isCreate() {
      return !this.formData
    },
    chackDupricateKey() {
      // TODO バリデーションのしくみを考える
      if (this.key == "" || (this.formData && this.formData.key == this.key)) {
        return []
      }

      const result = []
      if (this.isDupricateKey) {
        result.push("キーが重複しています")
      }
      return result
    },

    params() {
      // API用に変換
      const params = {}
      Object.keys(INITIAL_FORM_DATA).forEach(k=> {
        params[k] = this[k]
      })

      if (this.isCreate) {
        delete params.id
      }
      return {
        ...params,
        latitude: parseFloat(this.latitude),
        longitude: parseFloat(this.longitude),
        extra_fields: JSON.stringify(this.extraFields),
        is_active: 1
      }
    },
    latLng() {
      return new google.maps.LatLng(this.latitude, this.longitude)
    },
    logoUrl() {
      return '/static/images/icon/fm.png'
    },
    genreMarker() {
      return {
        id: "fm",
        name: "ファミリーマート",
        icon: "fm.png",
        db_key: "C1",
        value: "1",
      }
    },
  },
  methods: {
    // 取得したデータをフォーム用に変換
    setFormData(data) {
      Object.keys(data).forEach(key=> {
        if (key == 'extra_fields') {
          Object.keys(data.extra_fields).forEach(exKey => {
            this.extraFields[exKey] = data.extra_fields[exKey]
          })
        }
        this[key] = data[key]
      })
    },

    async onChangeKey() {
      // キー入力時の重複確認
      this.isDupricateKey = await this.service.isKeyExist(this.key)
    },

    initMap() {
      let latLng = null;
      if (this.latitude && this.longitude) {
        latLng = new google.maps.LatLng(this.latitude, this.longitude);
      } else {
        // 緯度経度がなければ東京駅を使用
        latLng = new google.maps.LatLng(35.681236, 139.767125);
      }
      mapService.init(latLng.lat(), latLng.lng());
      mapService.map.setCenter(latLng)
      mapService.map.setZoom(this.defaultZoom)
      this.setMarker(latLng)
    },

    // 座標更新
    updateLatlng(latLng, setPosition=false) {
      this.latitude = Math.round(latLng.lat() * 1000000) / 1000000;
      this.longitude = Math.round(latLng.lng() * 1000000) / 1000000;
      if(setPosition) {
        this.marker.setPosition(latLng)
        mapService.map.setCenter(latLng)
      }
      this.is_active = true;
    },

    // 住所を緯度経度に変換
    async addressToLatlng(address, name='') {
      this.isLoading = true;
      let query = address;
      if(name) query += ' ' + name;
      const httpclient = new HttpClient();
      const params = {address: query}
      await httpclient.get('/api/geocoding', {params})
        .then(response=> {
          if (!response.data || response.data.error) {
            this.message('緯度経度情報の取得に失敗しました。建物名などを消去すると成功する場合があります。', "error")
            return
          }
          const latlng = new google.maps.LatLng(response.data.latitude, response.data.longitude);
          this.updateLatlng(latlng, true)
        })
        .catch(e => {
          console.error(e)
          this.message('緯度経度情報の取得に失敗しました。', "error")
        })
      this.isLoading = false;
    },

    // 緯度経度を住所に変換
    async latlngToAddress() {
      this.isLoading = true;
      const httpclient = new HttpClient();
      const params = {
        latitude: this.latitude,
        longitude: this.longitude,
        reverse: 1
      }
      await httpclient.get('/api/geocoding', {params})
        .then(response=> {
          if (!response.data || response.data.error) {
            this.message('緯度経度情報の取得に失敗しました。', "error")
            return
          }
          const result = response.data.result
          let address = result.formatted_address;
          let key = '日本、';
          if (address.lastIndexOf(key, 0) === 0) {
            address = address.substr(key.length);
          }
          this.address = address.replace(/^〒\d{3}-\d{4}\s*/, "");
        })
        .catch(e => {
          console.error(e)
          this.message('緯度経度情報の取得に失敗しました。', "error")
        })
      this.isLoading = false;
    },
    // ロゴ入りのマーカーセット
    setMarker(latLng = this.latLng) {
      if(this.marker) {
        this.marker.setMap(null)
        this.marker = null
      }
      this.marker = new google.maps.Marker({
        position: latLng,
        draggable: true,
        callback: this.updateLatlng
      });
      // デフォルトマーカー
      this.marker.addListener('dragend', (e) => {
        this.updateLatlng(e.latLng)
      });
      this.marker.setMap(mapService.map);
      // this.marker = new OverlayMarker(
      //   mapService.map,
      //   latLng,
      //   this.logoUrl,
      //   {
      //     draggable: true,
      //     callback: this.updateLatlng,
      //     className: null
      //   }
      // )
      // this.marker.setMap(mapService.map)
    }
  }
}
</script>

<style lang="scss">

// marker
$marker-one-w: 32px;
$marker-one-h: 32px;
$marker-one-foot: 6px;
$marker-many-w: 40px;
$marker-many-h: 40px;
$primary: #e30200;

.bottom-divider {
  max-width: none;
}

.map-wrapper {
  height: 400px;
}
.thumbnail {
  margin: 6px 0;
  max-height: 50px;
}
.delete-btn {
  margin-left: 10px;
}

.marker {
  color: #666;
  width: $marker-one-w;
  height: $marker-one-h;
  margin-top: calc(#{$marker-one-h} / -2 - #{$marker-one-foot});
  background-color: rgba(255, 255, 255, 1);
  border: solid 1px $primary;
  border-radius: 50%;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
  transform-origin: bottom center;
  box-shadow: 0 5px 8px rgb(0 0 0 / 30%);
  background-color: #e30200 !important;
  border-color: #e30200 !important;

  .icon {
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(0.6);
    width: 100%;
    height: 100%;
    display: block
  }

  // beforeの座標は親の左上がデフォルトの座標
  &:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: $marker-one-foot solid $primary;
    //マーカーの高さ - 微調整
    margin: calc(#{$marker-one-h} - 2px)  auto 0 auto;
  }
  &.gr {
    border-color: #f89430;
    &::before {
      border-top-color: #f89430;
    }
  }
  &.sp {
    border-color: #f6c231;
    &::before {
      border-top-color: #f6c231;
    }
  }
  &.etc {
    border-color: #91a1ac;
    &::before {
      border-top-color: #91a1ac;
    }
  }
  &.dr {
    border-color: #ef4747;
    &::before {
      border-top-color: #ef4747;
    }
  }
  &.fs {
    border-color: #58d5e4;
    &::before {
      border-top-color: #58d5e4;
    }
  }
  &.ls {
    border-color: #f36fa1;
    &::before {
      border-top-color: #f36fa1;
    }
  }
  &.kd {
    border-color: #3a4678;
    &::before {
      border-top-color: #3a4678;
    }
  }
  &.ht {
    border-color: #c5af85;
    &::before {
      border-top-color: #c5af85;
    }
  }
  &.spr {
    border-color: #97ce3f;
    &::before {
      border-top-color: #97ce3f;
    }
  }
  &.mall {
    border-color: #8a68a5;
    &::before {
      border-top-color: #8a68a5;
    }
  }
  &.cvs {
    border-color: #389ddf;
    &::before {
      border-top-color: #389ddf;
    }
  }
}
.change.v-input--is-disabled.v-text-field--outlined {
  // ボーダー
  fieldset {
      border-color: red !important
  }
  // ラベル
  label {
      color: red !important
  }
  // テキスト
  input {
      color: red !important
  }
}
.map-caption {
  font-size: 12px;
  letter-spacing: .02em;
}
</style>