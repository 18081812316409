<template>
  <section class="multi-factor-auth">
    <Relogin v-if="needRelogin" />
    <v-form ref="mfaform" v-else-if="!loading">
      <div>
        <div v-if="!hasFactor" class="my-6">
          二段階認証は未設定です。
        </div>
        <v-table v-else class="mb-4">
          <thead>
            <tr>
              <th>電話番号</th>
              <th>登録日</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="f in multiFactor.enrolledFactors" class="text-left">
              <td>{{formatPhoneNumber(f.phoneNumber)}}</td>
              <td>{{formatDateTime(f.enrollmentTime, 'RFC_1123')}}</td>
              <td class="text-right">
                <ConfirmButton
                  icon
                  icon-name="close"
                  icon-color="grey"
                  title="確認"
                  :message="`電話番号 ${formatPhoneNumber(f.phoneNumber)} を削除しますか？`"
                  :callback="()=>deleteFactor(f)"
                />
              </td>
            </tr>
          </tbody>
        </v-table>

        <Recaptcha ref="recaptcha" invisible/>

        <div class="accountform">
          <v-btn
            block
            large
            color="primary"
            depressed
            @click="onAddPhoneNumber"
          >
            電話番号を追加
          </v-btn>

          <v-btn
            class="mt-4"
            block
            text
            large
            depressed
            @click="historyBack()"
          >
            戻る
          </v-btn>
        </div>
      </div>
    </v-form>
    <LoadingBar v-else />

    <v-dialog v-model="reauthDialog" width="400" persistent>
      <v-card class="pb-4">
        <v-card-title>
          再認証
        </v-card-title>
        <v-divider class="mt-0 mb-4"></v-divider>
        <div v-if="reauthStep == 1">
          <v-card-text class="mb-0">
            <p class="mb-4 text-left">
              アカウントの認証情報を変更するには再認証が必要です、パスワードを入力して再認証してください。
            </p>
            <v-form @submit.prevent="onReauthWithPassword">
              <v-text-field
                v-model="currentPassword"
                id="current-password"
                autocomplete="current-password"
                density="compact"
                type="password"
                label="パスワード"
                :rules="[rules.required, rules.min6]"
                color="primary"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions justify="end">
            <v-spacer></v-spacer>
            <v-btn depressed class="mr-2 ml-auto" @click="resetReauthState">
              キャンセル
            </v-btn>
            <v-btn
              depressed
              type="submit"
              color="primary"
              @click="onReauthWithPassword"
              :disabled="currentPassword.length < 6"
            >
              再認証
            </v-btn>
          </v-card-actions>
        </div>
        <div v-else>
          <MultiFactor
            :resolver="resolver"
            :recaptchaVerifier="recaptchaVerifier"
            @authorized="successReauth"
            @cancel="resetReauthState"
          />
        </div>
      </v-card>
    </v-dialog>

    <MultiFactorDialog
      v-model="mfaDialog"
      title="二段階認証登録"
      :resolver="resolver"
      :user="auth.currentUser"
      :next="nextFunction"
      :errorCallback="checkFirebaseError"
      :recaptchaVerifier="recaptchaVerifier"
    />

    <Alert></Alert>

  </section>
</template>

<script>
/**
 * Firebase Auth使用時の二段階認証設定View
 */

import {
  getAuth,
  onAuthStateChanged,
  getMultiFactorResolver,
  EmailAuthProvider,
  reauthenticateWithCredential,
  signInWithCredential,
  sendEmailVerification,
  AuthErrorCodes,
  multiFactor,
} from "firebase/auth";

import Alert from '@/components/Alert'
import AlertService from '@/services/alertservice'
import MultiFactorDialog from '@/components/firebase/MultiFactorDialog'
import ConfirmButton from "@/components/form/ConfirmButton"
import Recaptcha from "@/components/firebase/Recaptcha"
import MultiFactor from '@/components/firebase/MultiFactor'
import Relogin from '@/components/firebase/Relogin'
import LoadingBar from '@/components/LoadingBar'
import rules from "@/utils/rules"
import { showErrorMessageByCode } from '@/utils/firebase'
import { format } from 'date-fns'
const REAUTH_RESULT = {
  SUCCESS: 'SUCCESS',
  MFA_REQUIRED: 'MFA_REQUIRED',
  ERROR: 'ERROR',
}
let self = null
export default {
  components: {
    MultiFactorDialog,
    ConfirmButton,
    Recaptcha,
    MultiFactor,
    Alert,
    Relogin,
    LoadingBar,
  },
  created() {
    self = this
  },
  mounted() {
    this.init()
  },
  data() {
    return {
      auth: getAuth(),
      resolver: {},

      email: '',
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',

      loading: false,
      completed: false,
      mfaDialog: false,

      multiFactor: {},
      hasFactor: false, // multiFactorがreactiveに取れてないので応急処置

      needRelogin: false,
      reauthed: false,
      reauthDialog: false,
      reauthStep: 1, // 1: password, 2: mfa
      recaptchaVerifier: {},
      rules: rules,
      nextFunction: ()=>{},
      reauthedFunction: null,
    }
  },
  methods: {
    formatDateTime(datetime) {
      return format(new Date(datetime), 'yyyy/MM/dd HH:mm')
    },

    formatPhoneNumber(phoneNumber) {
      // 国番号を省略し、0を足す 例: '+819012345678' => '09012345678'
      if(phoneNumber.match(/^\+81[1-9]+/)) {
        return phoneNumber.replace('+81', '0')
      }
      return phoneNumber.replace('+81', '')
    },

    async init() {
      this.loading = true
      onAuthStateChanged(this.auth, user => {
        if(user) {
          this.email = user.email
          this.multiFactor = multiFactor(user)
          this.hasFactor = this.multiFactor.enrolledFactors.length > 0
        } else {
          this.needRelogin = true
        }
        this.loading = false
      },
      error => {
        console.error(error)
        this.needRelogin = true
      })
    },

    async deleteFactor(factor) {
      // 電話番号の削除
      try {
        await this.multiFactor.unenroll(factor)
        this.hasFactor = this.multiFactor.enrolledFactors.length > 0
        AlertService.showInfo('削除しました')
      }
      catch (e) {
        this.reauthedFunction = () => {this.deleteFactor(factor)}
        this.checkFirebaseError(e)
      }
    },

    async onAddPhoneNumber() {
      this.recaptchaVerifier = await this.$refs.recaptcha.initRecaptcha()
      await this.auth.currentUser?.reload()
      if(!this.auth.currentUser?.emailVerified) {
        sendEmailVerification(this.auth.currentUser)
        AlertService.showInfo(`メールアドレスの確認が必要です。${this.auth.currentUser.email}に確認メールを送信しました。しばらく経っても受信できない場合、迷惑メールに分類されている場合があります。`)
      } else if(this.auth.currentUser) {
        this.mfaDialog = true
        this.nextFunction = this.addedPhoneNumber
      }
    },

    checkFirebaseError(e) {
      if(e.code == 'auth/requires-recent-login') {
        // NOTE: 認証後も5分程度で再認証が必要になる
        this.reauthed = false
        this.reauth()
      } else {
        showErrorMessageByCode(e.code)
      }
    },

    async addedPhoneNumber() {
      this.multiFactor = multiFactor(this.auth.currentUser)
      this.hasFactor = this.multiFactor.enrolledFactors.length > 0
      AlertService.showInfo(`電話番号を追加しました`)
    },

    async reauth() {
      // 再認証ダイアログの表示
      // emailはauthから取るためパスワードとReCaptchaのみ
      this.recaptchaVerifier = await this.$refs.recaptcha.initRecaptcha()
      this.reauthDialog = true
    },

    async onReauthWithPassword() {
      // メールアドレスと現在のパスワードで再認証する
      // 二段階認証が設定されている場合はそちらも実行
      const authCredential = EmailAuthProvider.credential(this.email, this.currentPassword.trim())
      const result = await this._reauthenticateWithCredential(this.auth.currentUser, authCredential)

      if(result === REAUTH_RESULT.SUCCESS) {
        this.successReauth()
      } else if(result === REAUTH_RESULT.MFA_REQUIRED) {
        this.reauthStep = 2
      }
    },

    successReauth() {
      AlertService.showInfo('再認証に成功しました')
      this.reauthed = true
      if(typeof this.reauthedFunction === 'function') {
        this.reauthedFunction()
      }
      this.resetReauthState()
    },

    resetReauthState() {
      this.reauthStep = 1
      this.reauthedFunction = null
      this.reauthDialog = false
      this.currentPassword = ''
    },

    async _reauthenticateWithCredential(user, credential) {
      return reauthenticateWithCredential(user, credential)
        .then(()=> {
          // 再認証成功
          return REAUTH_RESULT.SUCCESS
        })
        .catch(error => {
          // 再認証に二段階認証が設定されていると失敗する
          // 二段階認証が要求された場合、errorからMFAのresolverを取得する
          if(error.code == AuthErrorCodes.MFA_REQUIRED) {
            this.resolver = getMultiFactorResolver(this.auth, error)
            return REAUTH_RESULT.MFA_REQUIRED
          } else {
            // それ以外ではエラー表示
            showErrorMessageByCode(error.code)
            return REAUTH_RESULT.ERROR
          }
        })
        .finally(()=>{
          this.loading = false
        })
    },

    async _signInWithCredential() {
      return signInWithCredential(this.email, this.currentPassword.trim())
        .then(()=> {
          return REAUTH_RESULT.SUCCESS
        })
        .catch(error => {
          if(error.code == AuthErrorCodes.MFA_REQUIRED) {
            this.resolver = getMultiFactorResolver(this.auth, error)
            return REAUTH_RESULT.MFA_REQUIRED
          } else {
            // this.showMessageMessageByCode(error)
            return REAUTH_RESULT.ERROR
          }
        })
        .finally(()=>{
          this.loading = false
        })
    },

    historyBack() {
      if(history.length > 1) {
        history.back();
      } else {
        location.href= '/account/user'
      }
    }

  },
}
</script>

<style lang="scss">
.overflow-hidden {
  overflow: hidden;
}
</style>
