<template>
  <div class="console-table">
    <v-data-table
      class="table"
      hide-default-footer
      :headers="tableHeader"
      :server-items-length="service.total"
      :items="service.items"
      :items-per-page="limit"
      :loading="service.isLoading"
      no-data-text="データがありません"
      @click:row="onClickRow"
      @update:sortBy="updateSortBy"
      >
      <template v-for="(col, idx) in cols" v-slot:[col.slot]="{ item }">
        <span :class="`console-table__cell__${col.key}`" v-if="col.type === 'date'" :key="col.slot + idx + 'date'">
          {{ formatDate(item.columns[col.key]) }}
        </span>
        <span :class="`console-table__cell__${col.key}`" v-else-if="col.type === 'datetime'" :key="col.slot + idx + 'datetime'">
          {{ formatDatetime(item.columns[col.key]) }}
        </span>
        <span :class="`console-table__cell__${col.key}`" v-else-if="col.type === 'select'" :key="col.slot + idx + 'select'">
          {{ col.options[item.columns[col.key]] }}
        </span>
        <template v-else-if="col.type === 'image'">
          <span :class="`console-table__cell__${col.key}`" v-if="!!item.columns[col.key]" :key="col.slot + idx + 'image'">
            <img :src="item.columns[col.key]" :alt="col.label" class="image">
          </span>
          <span :class="`console-table__cell__${col.key}`" v-else :key="col.slot + idx + 'no-image'">-</span>
        </template>
        <span :class="`console-table__cell__${col.key}`" v-else-if="col.type === 'boolean'" :key="col.slot + idx + 'boolean'">
          {{ col.boolText[[item.columns[col.key] - 0]] }}
        </span>
        <a :class="`console-table__cell__${col.key}`" :href="col.href(item)" v-else-if="col.type === 'link'" :key="col.slot + idx + 'link'" v-if="col.href(item)">
          {{ col.text }}
        </a>
        <span :class="`console-table__cell__${col.key}`" v-else :key="col.slot + idx">
          {{ col.text ? col.text(item) : item.columns[col.key] }}
        </span>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-menu bottom left>
          <template v-slot:activator="{ props }">
            <v-btn icon v-bind="props">
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(ta, idx) in tableActions">
              <v-list-item v-if="ta.condition ? ta.condition(item) : true" :key="idx">
                <v-list-item-title>
                  <v-btn variant="text" @click="handleAction(ta.action, item.raw.id, ta.func)">{{ta.label}}</v-btn>
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagenation"
      class="mt-4"
      :modelValue="currentPage"
      :length="Math.ceil(service.total / limit)"
      :total-visible="7"
      :border="true"
      :rounded="true"
      @input="pageChange"
      size="small"
    ></v-pagination>
  </div>
</template>

<script>
import {format, parse} from 'date-fns'

export default {
  props: {
    fields: {
      type: Array,
      required: true,
    },
    service: {
      type: Object,
      required: true,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 20,
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    tableActions: {
      type: Array,
      required: true
    },
    rowClickAction: {
      type: Object
    },
    rowClassConditions: {
      type: Array,
      default: () => []
    },
  },
  watch: {},
  data() {
    return {}
  },
  computed: {
    pagenation: {
      get() {
        return this.currentPage;
      },
      set(pageNum) {
        this.$emit('pageChange', pageNum)
      }
    },
    hasData() {
      if(this.service.items) {
        return this.service.items.length
      }
      return false
    },

    tableHeader() {
      const headers = this.fields.map(f => {
        return {
          title: f.label,
          align: 'start',
          sortable: f.sortable,
          value: f.key,
          key: f.key,
          width: f.width ? f.width : null
        }
      })
      if (this.tableActions.length > 0) {
        headers.push({
          title: "操作",
          align: 'center',
          sortable: false,
          value: "action",
          key: "action",
          width: '80px'
        })
      }
      return headers
    },
    cols() {
      return this.fields.map(f => {
        return {
          slot: `item.${f.key}`,
          key: f.key,
          type: f.type,
          boolText: f.boolText,
          // for link
          text: f.text,
          href: f.href,
          options: f.options
        }
      })
    },
  },
  methods: {
    async updateSortBy(sortBy) {
      if (!sortBy.length) {
        await this.sort(null, "");
        return;
      }
      await this.sort(sortBy[0].key != undefined ? sortBy[0].key : "", sortBy[0].order == "desc" ? "desc" : "")
    },
    formatDate(date) {
      if (!date) return ''
      return format(parse(date, 'yyyy-MM-dd', new Date()), 'yyyy/MM/dd')
    },
    formatDatetime(datetime) {
      if (!datetime) return ''
      return format(parse(datetime, 'yyyy-MM-dd HH:mm:ss', new Date()), 'yyyy/MM/dd')
    },
    pageChange(page) {
      this.$emit('pageChange', page);
    },
    sort(field, order) {
      this.$emit('sortChange', field, order)
    },
    handleAction(action, id, func) {
      if(action === 'custom') {
        func(id);
      } else {
        this.$emit(action, id);
      }
    },
    // getRowClass(row) {
    //   if(this.rowClassConditions.length) {
    //     const classNames = this.rowClassConditions.filter(c => c.condition(row)).map(c => c.className)
    //     return classNames.join(' ')
    //   }
    //   return ""
    // },
    onClickRow(event, data) {
      if (!this.rowClickAction.action) return;
      this.handleAction(this.rowClickAction.action, data.item.raw.id, this.rowClickAction.func)
    }
  }
};
</script>
<style lang="scss">
@import "../../../styles/variables.scss";
.console-table {
  table {
    font-size: 14px;
    tbody tr:nth-of-type(even) td {
      background-color: rgba(0, 0, 0, .02)!important;
      .v-btn {
        background-color: rgba(0, 0, 0, .001);
      }
    }
    th, td {
      padding: 12px;
    }
    thead tr th.v-data-table__td.v-data-table-column--align-end {
      .v-data-table-header__content {
        margin-right: 10px;
      }
    }
  }
  .image {
    max-width: 300px;
  }
  // フッターを隠すpropsが無いためcssで非表示にする
  .v-data-table-footer {
    display: none;
  }
  li.v-pagination__item {
    .v-btn {
      font-size: 1rem;
    }
    &.v-pagination__item--is-active {
      .v-btn {
        background: $theme-color;
        color: #fff;
      }
    }
  }
  // class名をつけるprop（item-class）がないため、cssで指定
  .v-data-table__tr {
    cursor: pointer;
  }
}
</style>