<template>
  <div>
    認証用のセッションが無効になりました。<br/>
    再度ログインしてください。
    <div class="py-4">
      <v-btn
        color="primary"
        href="/account/logout/"
      >
        ログアウト
      </v-btn>
    </div>
  </div>
</template>

<script>

export default {
  mounted() {
  },
}
</script>

<style lang="scss">
</style>
